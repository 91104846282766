/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

interface ILanguage {
  sign_in: {
    h1: string;
    h2: string;
    p_1: string;
    p_2: string;
    label_1: string;
    label_2: string;
    a_1: string;
    a_2: string;
    button: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  sign_up: {
    h1: string;
    h2: string;
    p_1: string;
    p_2: string;
    p_3: string;
    a_1: string;
    a_2: string;
    label_1: string;
    label_2: string;
    label_3: string;
    button_1: string;
    label_4: string;
    button_2: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    erro_5: string;
    erro_6: string;
    erro_7: string;
    erro_8: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  forgot_password: {
    arrow: string;
    h1: string;
    h2: string;
    label_1: string;
    a: string;
    button: string;
    erro_1: string;
    erro_2: string;
    success: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  change_password: {
    h1: string;
    label_1: string;
    label_2: string;
    a: string;
    button: string;
    erro_1: string;
    erro_2: string;
    success: string;
    erro_3: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  header: {
    small_1: string;
    small_2: string;
    small_3: string;
    small_4: string;
    small_5: string;
    small_6: string;
    small_7: string;
    button_1: string;
    button_2: string;
    small_8: string;
  };
  courses: {
    h1: string;
    input: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
    button_5: string;
  };
  course: {
    p_1: string;
    p_2: string;
    p_3: string;
    button_1: string;
    h2: string;
    h2_1: string;
    h2_2: string;
    p_4: string;
    h4: string;
    button_2: string;
  };
  dashboard: {
    h3_1: string;
    h3_2: string;
    button: string;
  };
  discover: {
    h3: string;
    p: string;
    button: string;
  };
  my_courses: {
    h3: string;
    button: string;
    p: string;
  };
  upcoming: {
    h3: string;
    button: string;
    b: string;
    p: string;
  };
  certificates: {
    h3: string;
    p: string;
  };
  lesson: {
    p_1: string;
    small: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
    button_5: string;
    button_6: string;
    button_7: string;
    h2_1: string;
    h2_2: string;
    p_2: string;
    p_3: string;
    h3_1: string;
    h3_2: string;
    h2_3: string;
    h2_4: string;
    p_4: string;
    time: string;
    success_1: string;
    success_2: string;
    modal_coach_label: string;
    modal_title: string;
    modal_p_1_1: string;
    modal_p_1_2: string;
    modal_a: string;
    modal_p_1_3: string;
  };
  profile: {
    h1: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
    button_5: string;
  };
  my_profile: {
    label_1: string;
    h3_1: string;
    label_2: string;
    label_3: string;
    label_4: string;
    h3_2: string;
    label_5: string;
    label_6: string;
    label_7: string;
    label_8: string;
    label_9: string;
    label_10: string;
    label_11: string;
    label_12: string;
    label_13: string;
    label_14: string;
    label_15: string;
    h3_3: string;
    label_16: string;
    label_17: string;
    label_18: string;
    button_1: string;
    button_2: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    erro_5: string;
    erro_6: string;
    erro_7: string;
    erro_8: string;
    success_1: string;
    success_2: string;
  };
  payment_plans: {
    h2_1: string;
    span_1: string;
    h2_2: string;
    p: string;
    h2_3: string;
    h2_4: string;
    h2_5: string;
    h2_6: string;
    placeholder: string;
    h2_7: string;
    h2_8: string;
    button: string;
    status_1: string;
    status_2: string;
    status_3: string;
    status_4: string;
    status_5: string;
    status_6: string;
    modal_h2_1: string;
    modal_h2_2: string;
    modal_h3_1: string;
    modal_p_1: string;
    modal_p_2: string;
    modal_p_3: string;
    modal_p_4: string;
    modal_p_5: string;
    modal_button_1: string;
    modal_h3_2: string;
    modal_p_6: string;
    modal_p_7: string;
    modal_p_8: string;
    modal_button_2: string;
  };
  my_purchases: {
    h3_1: string;
    h3_2: string;
    button_1: string;
    button_2: string;
    button_3: string;
  };
  subscriptions_cards: {
    h2: string;
    th_1: string;
    th_2: string;
    th_3: string;
    th_4: string;
    th_5: string;
    th_6: string;
    button_1: string;
    button_2: string;
    h4_1: string;
    input_1: string;
    input_2: string;
    input_3: string;
    input_4: string;
    input_5: string;
    button_3: string;
    button_4: string;
    h4_2: string;
    p: string;
    button_5: string;
    button_6: string;
  };
  receipts: {
    b_1: string;
    b_2: string;
    b_3: string;
  };
  resources: {
    h1: string;
    input: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
  };
  become_affiliate: {
    h1: string;
    h2_1: string;
    p_1: string;
    p_2: string;
    h3_1: string;
    h3_2: string;
    img_1: string;
    h2_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    p_6: string;
    p_7: string;
    p_8: string;
    p_9: string;
    p_10: string;
    p_11: string;
    p_12: string;
    p_13: string;
    p_14: string;
    a_1: string;
    button_1_1: string;
    button_1_2: string;
    img_2: string;
    h2_3: string;
    p_15: string;
    p_16: string;
    p_17: string;
    p_18: string;
    p_19: string;
    p_20: string;
    p_21: string;
    p_22: string;
    p_23: string;
    p_24: string;
    p_25: string;
    p_26: string;
    a_2: string;
    button_2_1: string;
    button_2_2: string;
    span_1: string;
    span_2: string;
    span_3: string;
  };
  products: {
    h1: string;
    h2: string;
    input: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
  };
  product_franchise: {
    p_1: string;
    p_2: string;
    p_3: string;
    button_1: string;
    button_2: string;
    button_3: string;
  };
  requires_purchase: {
    requires_1: string;
    requires_2: string;
  };
  faq: {
    h1: string;
    input: string;
    img: string;
    p: string;
  };
  footer: {
    h3_1_1: string;
    h3_1_2: string;
    h3_2_1: string;
    h3_2_2: string;
    h2: string;
    p_1: string;
    small: string;
    button: string;
    h4: string;
    b: string;
    p_2: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  buy_component: {
    button_1: string;
    button_2: string;
    h4_1: string;
    button_3: string;
    button_5: string;
    error_message: string;
    h4_2: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    erro_5: string;
    yup: string;
  };
  error_component: {
    error_message_axios: string;
    error_message: string;
    verification_message: string;
    all_right_message: string;
  };
  gift_required: {
    required: string;
  };
  credit_card_component: {
    input_1: string;
    input_2: string;
    input_3: string;
    input_4: string;
    input_5: string;
    label_1: string;
    label_2: string;
    label_3: string;
    label_4: string;
  };
  add_credit_card_component: {
    title: string;
    button: string;
  };
  quiz_component: {
    button_1: string;
    button_2: string;
    h4: string;
    p: string;
    button_3: string;
    error_message: string;
    alert_message: string;
    certificate_message: string;
  };
  select_component: {
    input: string;
  };
  password_component: {
    button_1: string;
    button_2: string;
  };
  no_register: {
    p: string;
  };
  course_not_found: {
    h1: string;
    button: string;
  };
  buy_product_button: {
    h3: string;
    span: string;
    button_1: string;
    button_2: string;
  };
  buy_product_button_binance: {
    h4: string;
    span_1: string;
    span_2: string;
    p_1: string;
    p_2: string;
    a: string;
  };
  buy_product_button_tether: {
    button_1: string;
    button_2: string;
    h3: string;
    placeholder: string;
    span: string;
  };
  buy_product_button_gift_card: {
    title: string;
    label_1: string;
    label_2: string;
    label_3: string;
    button: string;
  };
  message_timeout: {
    msg_1: string;
    msg_2: string;
    msg_3: string;
  };
  update_warning: {
    h1: string;
    p: string;
  };
  payment: {
    h1: string;
    p_1: string;
    h2_1: string;
    p_2: string;
    p_3_1: string;
    p_3_2: string;
    p_4_1: string;
    p_4_2: string;
    h6_1: string;
    h6_2: string;
    span_1: string;
    h6_3: string;
    span_2: string;
    h6_4: string;
    button: string;
    a: string;
    modal_h2: string;
    modal_p_1: string;
    modal_p_2: string;
    modal_p_3: string;
    modal_label: string;
    modal_img: string;
    modal_button_1: string;
    modal_button_2: string;
    modal_info: string;
  };

  table: {
    p_1: string;
    p_2_1: string;
    p_2_2: string;
    button_1: string;
    button_2: string;
  };

  notes: {
    p: string;
  };

  support: {
    h4: string;
    p: string;
    a: string;
  };

  footer_coach: {
    h4: string;
    p_1: string;
    small: string;
    p_2: string;
    p_3: string;
    span: string;
  };

  footer_next_step: {
    h2: string;
    p: string;
    button: string;
  };
}

interface LanguageContextData {
  language: ILanguage;
  handleSelectIdiom(idiom: string): void;
  idiom: string;
}

export const LanguageContext = createContext<LanguageContextData>(
  {} as LanguageContextData
);

export const LanguageProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [idiom, setIdiom] = useState(() => {
    let lang = location.pathname.substr(1, 2);
    if (lang !== 'en' && lang !== 'es') {
      lang = localStorage.getItem('@Membership:language') || 'en';
    }
    const element = document.getElementsByTagName('html');
    element[0].lang = lang;
    return lang;
  });

  const handleSelectIdiom = useCallback((idiomSelected) => {
    setIdiom(idiomSelected);
    const element = document.getElementsByTagName('html');
    element[0].lang = idiomSelected;
  }, []);

  const language = useMemo(() => {
    const checkIdiom = localStorage.getItem('@Membership:language');
    if (checkIdiom !== idiom) {
      const lang = location.pathname.substr(1, 2);
      if (lang !== 'en' && lang !== 'es') {
        history.push(`${process.env.PUBLIC_URL}/${idiom}${location.pathname}`);
      } else {
        history.push(
          `${process.env.PUBLIC_URL}/${idiom}${location.pathname.substr(3)}`
        );
      }
    }

    localStorage.setItem('@Membership:language', idiom);

    return require(`./languages/${idiom}`);
  }, [history, idiom, location.pathname]);

  return (
    <LanguageContext.Provider value={{ language, handleSelectIdiom, idiom }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage(): LanguageContextData {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
