import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

export const Container = styled.div`
  .w-75-profile {
    input {
      width: 75%;
    }
  }
  .border-color {
    border: solid 1.5px var(--closedGray);
  }
  .bg-gray {
    background: var(--coal);
    padding: 20px;
    border-radius: 20px;
  }

  .border-custom {
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: var(--closedGray);
  }

  .profile-photo-icon {
    right: 0px;
    bottom: 0px;
    cursor: pointer;
  }

  label {
    color: var(--grayImmensity);
  }

  .profile-button {
    border: none;
    background-color: var(--greyMineral);
    border-radius: 15px;

    :hover {
      color: var(--blizzardLighten) !important;
      background-color: var(--greyMineralLighten) !important;
      transition: 0.5s;
    }
  }

  .py-adress {
    > div {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  }

  .profile-photo {
    width: 100px;
    cursor: pointer;
    height: 100px;
    padding: 3px;
    background-image: linear-gradient(0deg, var(--coal), var(--coal)),
      linear-gradient(180deg, var(--blizzardOpacity), var(--blizzardOpacity));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  .contact-info {
    input {
      width: 100%;
    }
  }

  .button-color {
    background: var(--graniteGray);
    font-weight: 600;
    border-bottom: 1px solid var(--graniteGray) !important;
    line-height: 1;
  }

  .checkbox-width {
    width: 30px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .border-custom {
      border-bottom-width: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .bg-gray {
      padding: 0 20px;
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 91px;
  height: 91px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px var(--blizzardOpacity);
  box-sizing: border-box;
`;
