import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';
import { VideoPlayer } from './styles';

interface IPlayerProps {
  src: string;
  id?: string;
  className?: string;
  thumbnail?: string;
  onPlay?(): void;
  onPause?(): void;
  callChangeTimeBySeconds?: number;
  onChangeTime?(time: number): void;
  onComplete?(): void;
}

const Player: React.FC<IPlayerProps> = ({
  src,
  id,
  className,
  thumbnail,
  onPlay,
  onPause,
  callChangeTimeBySeconds,
  onChangeTime,
  onComplete,
}) => {
  const location = useLocation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [time, setTime] = useState(0);
  const [showPlayer, setShowPlayer] = useState(false);

  const handlePlayVideo = useCallback(() => {
    if (onPlay) {
      onPlay();
    }
  }, [onPlay]);

  const handlePauseVideo = useCallback(() => {
    if (onPause) {
      onPause();
    }
  }, [onPause]);

  // const handleChangeTime = useCallback(
  //   (e) => {
  //     const videoTime = parseInt(e.currentTime, 10);
  //     const callTime = callChangeTimeBySeconds || 10;
  //     if (time !== videoTime && videoTime % callTime === 0) {
  //       setTime(videoTime);
  //       if (onChangeTime) {
  //         onChangeTime(videoTime);
  //       }
  //     }
  //   },
  //   [callChangeTimeBySeconds, onChangeTime, time]
  // );

  const handleCompleteVideo = useCallback(() => {
    if (onComplete) {
      onComplete();
    }
  }, [onComplete]);

  useEffect(() => {
    const options = {
      fluid: true,
      preload: 'auto',
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },

      autoplay: false,
      controls: true,
      sources: [
        {
          src,
          type: 'video/mp4',
        },
      ],
      ended: handleCompleteVideo,
    };

    if (videoRef.current) {
      const player = videojs(videoRef.current, options);
      // eslint-disable-next-line no-underscore-dangle
      player.updateSourceCaches_(src);
      player.on('ended', handleCompleteVideo);
    }
  }, [handleCompleteVideo, src]);

  return (
    <div data-vjs-player>
      <VideoPlayer
        ref={videoRef}
        onPlay={handlePlayVideo}
        onPause={handlePauseVideo}
        id={id}
        className={`${className} video-js`}
        poster={thumbnail}
        controls
        src={src}
        playsInline
      >
        Sorry, your browser doesn't support embedded videos.
      </VideoPlayer>
    </div>
  );
};

export default Player;
