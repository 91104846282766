import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import play from '~/assets/icons/play-icon.svg';

import { Container } from './styles';

interface ButtonLessonProps {
  lesson: {
    id: string;
    title: string;
    duration: string;
    status: string;
    slug: string;
    finished: boolean;
  };
  slugCourse: string;
  onClickFinished?(lesson_id: string): void;
}

const ButtonLesson: React.FC<ButtonLessonProps> = ({
  lesson,
  slugCourse,
  onClickFinished,
}) => {
  return (
    <Container
      className="lesson-border w-100 my-1 d-flex aling-items-center px-2"
      active={lesson.status === 'current'}
    >
      <button
        type="button"
        onClick={() => onClickFinished && onClickFinished(lesson.id)}
        className="mr-3"
      >
        {lesson.finished ? (
          <AiOutlineCheckCircle
            size={24}
            color="#e4e4e4"
            className="completed"
          />
        ) : (
          <img src={play} alt="Icon Button" />
        )}
      </button>
      <Link
        to={`${process.env.PUBLIC_URL}/courses/${slugCourse}/${lesson.slug}`}
        className="d-flex justify-content-between py-2"
      >
        <div className="d-flex aling-items-center">
          <p className="mb-0 small ml-1 line-height">{lesson.title}</p>
        </div>
        <p className="mb-0">{lesson.duration}</p>
      </Link>
    </Container>
  );
};

export default ButtonLesson;
