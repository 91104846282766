import styled from 'styled-components';
import support from '~/assets/defaults/supportNew.png';

export const Container = styled.div`
  background-image: url(${support});
  background-size: cover;
  background-position: top center;
  border-radius: 15px;
  min-height: 450px;

  h4 {
    color: #f5f5f5;
    font-size: 18px;
    font-weight: 500;
  }

  p {
    color: #f5f5f5;
    font-size: 14px;
    font-weight: 300;
  }

  a {
    border-radius: 22px;
    border: 2px solid rgba(240, 240, 240, 0.12);
    background: rgba(26, 27, 29, 0.7);
    text-decoration: none;
    color: #f2f2f2;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    display: block;
  }

  @media screen and (max-width: 1199px) {
    min-height: 194px;
  }
`;
