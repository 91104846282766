import styled, { keyframes } from 'styled-components';

interface IBackground {
  src: string;
}

export const Container = styled.div`
  background-color: var(--asphaltGray);
  min-height: 100vh;

  .position {
    right: 0;
    top: 0;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  > div {
    background: var(--closedGray);
    background: linear-gradient(
      136deg,
      var(--closedGrayOpacity) 0%,
      var(--closedGray) 100%
    );

    border: none;
    padding: 1px;
    background-image: linear-gradient(
        136deg,
        var(--asphaltGray),
        var(--asphaltGray)
      ),
      linear-gradient(136deg, var(--grayImmensity), var(--closedGray));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    transition-duration: 0.3s;
    color: var(--ultraWhite);

    @media screen and (min-width: 992px) {
      width: 84%;
      margin: 0 auto;
    }

    @media screen and (min-width: 1200px) {
      width: 75%;
    }

    @media screen and (min-width: 1700px) {
      width: 60%;
    }
  }

  .login {
    background: linear-gradient(
      180deg,
      var(--closedGrayOpacity) 0.29%,
      var(--closedGray) 101.74%
    );

    border-radius: 10px;
    color: var(--graniteGray);
  }

  h1 {
    margin-bottom: 24px;
    color: var(--ultraWhite);
  }

  h2 {
    color: var(--graniteGray);
    font-weight: bold !important;
  }

  p {
    color: var(--graniteGray);
  }

  .normal-sm a {
    color: var(--graniteGray);
  }

  .font-weigth-500 {
    font-weight: 500 !important;
  }

  .button-login {
    background-color: var(--midnight);
    color: var(--blizzard);
    text-decoration: none;
    border: none;
    border-radius: 15px;

    :hover {
      background-color: var(--greyMineralLighten);
      transition: 0.5s;
    }
  }

  .small {
    color: var(--grayImmensity);
  }

  form {
    margin-top: 60px;

    .form-check-input {
      height: auto;
      width: auto;
    }

    > div {
      padding: 6.5px 10px;
      border: 1px solid var(--mysteriousGrey);
      background-color: var(--asphaltGray) !important;
    }

    input {
      width: 100%;
      height: 40px;
    }

    input[id='password'] {
      width: 77%;
    }

    .btn-show-custom {
      .button-show {
        position: absolute;
        top: -8px;
        right: 0px;
        width: 70px;
        text-align: center;
        background: none;
        border-width: 0px 0px 0px 1px;
        border-top-style: initial;
        border-right-style: initial;
        border-bottom-style: initial;
        border-top-color: initial;
        border-right-color: initial;
        border-bottom-color: initial;
        border-image: initial;
        font-weight: bold;
        padding: 6px;
        color: var(--graniteGray);
        height: 56px !important;
      }
    }

    @media screen and (min-width: 400px) {
      .d-400-flex {
        display: flex;
      }
    }

    @media screen and (max-width: 1199px) {
      .button-login {
        border-radius: 12px;
      }
    }

    @media screen and (max-height: 740px) {
      margin: 20px 0;
    }

    @media screen and (max-width: 450px) {
      input[id='password'] {
        width: 68%;
      }
    }

    a {
      color: var(--greyMineral);
      display: block;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: var(--greyMineralDarken);
      }
    }
  }

  @media screen and (max-width: 1400px) {
  }

  @media screen and (max-width: 1199px) {
    form {
      width: 100%;
      margin: 25px 0px 0px 0px;
    }

    .content {
      position: relative;
      z-index: 1;
      opacity: 0.8;

      a {
        color: var(--graniteGray);
      }
    }
  }

  @media screen and (max-width: 991px) {
    form {
      margin: 40px 0px 0px 0px;
    }
  }
`;

export const Background = styled.div<IBackground>`
  background-image: url(${(props) => props.src});
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;

  .content {
    position: relative;
    z-index: 1;
    opacity: 0.8;

    a {
      color: var(--graniteGray);
    }
  }

  @media screen and (max-height: 790px) {
    height: 116vh;
  }
`;
