import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';

import { useAuth } from '~/hooks/Auth';

interface IParams {
  token: string;
}

const CheckLogin: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { setLogin } = useAuth();

  useEffect(() => {
    api.get(`students/sessions/get-data/${params.token}`).then((response) => {
      if (response.data) {
        console.log(JSON.parse(response.data));
        const [token, user] = JSON.parse(response.data);
        setLogin(token, JSON.parse(user), params.token);
        history.push(`${process.env.PUBLIC_URL}/dashboard/my-courses`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/`);
      }
    });
  }, [history, params.token, setLogin]);

  return <div />;
};

export default CheckLogin;
