import styled from 'styled-components';

interface noteProps {
  textareaSelected: boolean;
}

export const Note = styled.div<noteProps>`
  background: var(--coal);
  border-radius: 15px;

  p {
    color: var(--greyMineral);
    font-size: 0.9rem;
  }

  button {
    cursor: text !important;
  }

  textarea {
    background: var(--coal);
    border: none;
    color: var(--graniteGray);
    min-height: ${(props) => (props.textareaSelected ? '124px' : '100px')};

    :focus {
      background: var(--coal);
      box-shadow: none;
      color: var(--graniteGray);
    }
  }

  @media screen and (max-width: 1199px) {
    textarea {
      min-height: ${(props) => (props.textareaSelected ? '134px' : '112px')};
    }
  }
`;
