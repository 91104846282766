import { Link } from 'react-router-dom';

import styled from 'styled-components';

interface IPropsBox {
  src: string;
}

export const Container = styled.div`
  padding-bottom: 20px;

  .banner-slider {
    .slick-dots {
      li {
        width: unset;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        button {
          background: rgba(196, 196, 196, 0.4);
          width: 10px;
          height: 10px;
          border-radius: 5px;
          transition-duration: 0.3s;

          ::before {
            display: none;
          }
        }
      }

      li.slick-active {
        button {
          width: 30px;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
`;

export const Courses = styled.div`
  h3,
  p {
    color: var(--blizzard) !important;
  }

  h3 {
    padding: 0 20px;
  }

  .stamp.visible {
    opacity: 1;
  }

  .stamp.hidden {
    opacity: 0;
  }

  .slick-list {
    padding: 20px 0 !important;
    height: 316px;
  }

  .slick-prev {
    top: 44%;
  }

  .slick-next {
    top: 44%;
  }

  .padding-box {
    padding: 0 20px;
  }

  @media screen and (max-width: 1399px) {
    .float-right {
      height: 25px;
    }
  }
`;

export const BoxBanner = styled.div<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 30vw;
  background: linear-gradient(
      284.04deg,
      rgba(95, 95, 95, 0) 0%,
      rgba(0, 0, 0, 0.585) 100%
    ),
    url(${(props) => props.src}), rgba(0, 0, 0, 0.1);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(240, 240, 240, 0.12);
      background: rgba(26, 27, 29, 0.7);
      color: #f2f2f2;
      text-decoration: none;
      border-radius: 22px;
      width: 200px;
      height: 64px;

      :hover {
        color: var(--blizzardLighten);
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--blizzard) !important;
    }
  }

  @media screen and (min-width: 1800px) {
    height: 546px;

    .align-center-1800 {
      align-items: center;
    }

    .h3-1800 {
      font-size: 1.75rem !important;
    }
  }

  @media screen and (max-width: 991px) {
    height: 29vw;
  }

  @media screen and (max-width: 575px) {
    height: 50vw;
  }
`;

export const BoxRecents = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 20vw;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition-duration: 0.3s;
  :hover {
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.45),
      0px 15px 64px -45px rgba(199, 197, 197, 0.42);
    transform: scale(1.11);
  }

  .see-more-link {
    button {
      border: none;
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;

      :hover {
        color: var(--blizzardLighten);
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--blizzard) !important;
    }
  }

  @media screen and (min-width: 1800px) {
    height: 238px;

    .align-center-1800 {
      align-items: center;
    }

    .h3-1800 {
      font-size: 1.75rem !important;
    }
  }

  @media screen and (max-width: 991px) {
    height: 29vw;
  }

  @media screen and (max-width: 575px) {
    height: 50vw;
  }
`;

export const BoxLoading = styled.div`
  .loading-recents {
    background-color: var(--closedGray);
    overflow: hidden;
    position: relative;
    height: 20vw;
    border-radius: 20px;

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      );
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }

    @media screen and (min-width: 1800px) {
      height: 238px;
    }

    @media screen and (max-width: 991px) {
      height: 29vw;
    }

    @media screen and (max-width: 575px) {
      height: 50vw;
    }
  }

  .loading-others {
    background-color: var(--closedGray);
    overflow: hidden;
    position: relative;
    height: 14.5vw;
    border-radius: 20px;

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      );
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }

    @media screen and (min-width: 1800px) {
      height: 238px;
    }

    @media screen and (min-width: 2200px) {
      height: 300px;
    }

    @media screen and (max-width: 991px) {
      height: 21vw;
    }

    @media screen and (max-width: 768px) {
      height: 25vw;
    }

    @media screen and (max-width: 575px) {
      height: 40vw;
    }
  }
`;

export const BoxOthers = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 14.5vw;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    button {
      border: none;
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;

      :hover {
        color: var(--blizzardLighten);
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--blizzard) !important;
    }
  }

  @media screen and (min-width: 1800px) {
    height: 238px;

    .align-center-1800 {
      align-items: center;
    }

    .h4-1800 {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (min-width: 2200px) {
    height: 300px;
  }

  @media screen and (max-width: 991px) {
    height: 21vw;
  }

  @media screen and (max-width: 768px) {
    height: 25vw;
  }

  @media screen and (max-width: 575px) {
    height: 40vw;
  }
`;
