/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { IoIosArrowBack } from 'react-icons/io';

import { Link } from 'react-router-dom';
import { useLanguage } from '~/hooks/Language';
import { useSetup } from '~/hooks/Setup';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer, Background } from './styles';
import Input from '~/components/Input';

import wiserr from '~/assets/defaults/wiserr.png';
import api from '~/services/api';

interface SignInFormData {
  email: string;
}

const ForgotPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { language } = useLanguage();
  const {
    setupName,
    logo,
    loginBackground,
    socialMedias,
    socialUserName,
  } = useSetup();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email(language.forgot_password.erro_1)
            .required(language.forgot_password.erro_2),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('students/sessions/forgot-password', {
          email: data.email,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        }
      }
      Swal.fire('', language.forgot_password.success, 'success');
    },
    [
      language.forgot_password.erro_1,
      language.forgot_password.erro_2,
      language.forgot_password.success,
    ]
  );

  return (
    <Container className="d-flex align-items-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center justify-content-lg-start align-items-center h-100">
          <Background
            src={loginBackground}
            className="d-none d-lg-block col-lg-6 text-center"
          >
            <div className="h-100 d-flex flex-column align-items-center justify-content-between py-5 content">
              <img src={logo} alt="Logo" />
              <div className="text-center w-100">
                <div className="d-flex justify-content-center">
                  {socialMedias.map((socialMedia) => (
                    <a
                      key={socialMedia.link}
                      href={socialMedia.link}
                      target="blank"
                    >
                      <img
                        src={socialMedia.icon}
                        alt="Social Media"
                        className="mx-2"
                      />
                    </a>
                  ))}
                </div>
                <p className="d-block my-2">{socialUserName}</p>
              </div>
            </div>
          </Background>
          {setupName === 'wiserr' && (
            <div className="p-absolute position d-none d-md-block">
              <img src={wiserr} alt="Wiserr" />
            </div>
          )}
          <AnimationContainer className="col-11 col-sm-9 col-lg-6 px-0 px-sm-3 my-5 my-lg-0">
            <div className="align-items-center mb-xl-3 shadow">
              <div className="p-xxl-5 p-lg-4 p-md-5 py-5 px-4 login">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="small w-sm-25 d-flex align-items-center font-weight-bold"
                >
                  <IoIosArrowBack size={25} />
                  &nbsp;&nbsp;{language.forgot_password.arrow}
                </Link>
                <h1 className="h5 h3-sm h2-md h3-lg h2-xl font-weight-bold align-items-center text-center text-md-left mt-5 mb-4 mb-xl-3">
                  {language.forgot_password.h1}
                </h1>
                <h2 className="h6 mb-1 mb-md-3 text-center text-md-left font-weight-300">
                  {language.forgot_password.h2}
                </h2>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <label htmlFor="email" className="d-block mb-1 mt-3 small">
                    {language.forgot_password.label_1}
                  </label>
                  <Input id="email" name="email" className="" />
                  <div className="d-flex px-0 align-items-center justify-content-between flex-wrap flex-lg-nowrap bg-transparent border-0">
                    <button type="submit" className="button-login mt-5 w-100">
                      <span className="text-white font-weight-bold d-block px-5 py-3">
                        {language.forgot_password.button}
                      </span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </AnimationContainer>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
