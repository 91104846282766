import styled, { css } from 'styled-components';
// import ReactJWPlayer from 'react-jw-player';

export const VideoPlayer = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: fill;

  > div {
    width: 100% !important;
    // height: 100% !important;
  }

  .vjs-poster img {
    object-fit: cover !important;
  }
`;
