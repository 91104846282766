import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '~/hooks/Language';
import { Container } from './styles';

const Support: React.FC = () => {
  const { language } = useLanguage();

  return (
    <Container className="d-flex flex-column p-4">
      <h4 className="mt-3 mb-3">{language.support.h4}</h4>
      <p className="mb-4">{language.support.p}</p>
      <Link to="/" className="mt-auto px-4 py-3 w-100">
        {language.support.a}
      </Link>
    </Container>
  );
};

export default Support;
