import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

interface IParams {
  email: string;
  password: string;
}

const AutoLogin: FC = () => {
  const params = useParams<IParams>();
  const { signIn } = useAuth();

  useEffect(() => {
    const { email, password } = params;
    signIn({ email, password, destination: 'resources/downloads' });
  }, [params, signIn]);

  return <div />;
};

export default AutoLogin;
