import { createGlobalStyle } from 'styled-components';
import { darken, lighten } from 'polished';

interface ITheme {
  ultraWhite: string;
  blizzard: string;
  graniteGray: string;
  technologicalGray: string;
  grayImmensity: string;
  deepGrey: string;
  greyMineral: string;
  mysteriousGrey: string;
  closedGray: string;
  asphaltGray: string;
  coal: string;
  midnight: string;
  error: string;
  warning: string;
  success: string;
  skeletonTransparent: string;
  skeletonQuarter: string;
  skeletonMiddle: string;
  skeletonFull: string;
}

export default createGlobalStyle<ITheme>`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap&family=Poppins:wght@300;400;500;600;700&display=swap');

  :root {
    --ultraWhite: ${(props) => props.ultraWhite};
    --ultraWhiteDarken: ${(props) => darken(0.1, props.ultraWhite)};
    --ultraWhiteOpacity: ${(props) => props.ultraWhite}80;
    --blizzard: ${(props) => props.blizzard};
    --blizzardLighten: ${(props) => lighten(0.1, props.blizzard)};
    --blizzardOpacity: ${(props) => props.blizzard}80;
    --graniteGray: ${(props) => props.graniteGray};
    --graniteGrayLighten: ${(props) => lighten(0.1, props.graniteGray)};
    --technologicalGray: ${(props) => props.technologicalGray};
    --technologicalGrayLighten: ${(props) =>
      lighten(0.1, props.technologicalGray)};
    --technologicalGrayDarken: ${(props) =>
      darken(0.2, props.technologicalGray)};
    --technologicalGrayOpacity: ${(props) => props.blizzard}80;
    --grayImmensity: ${(props) => props.grayImmensity};
    --deepGrey: ${(props) => props.deepGrey};
    --deepGreyLighten: ${(props) => lighten(0.1, props.deepGrey)};
    --deepGreyDarken: ${(props) => darken(0.05, props.deepGrey)};
    --deepGreyOpacity: ${(props) => props.deepGrey}4d;
    --greyMineral: ${(props) => props.greyMineral};
    --greyMineralLighten: ${(props) => lighten(0.1, props.greyMineral)};
    --greyMineralDarken: ${(props) => darken(0.15, props.greyMineral)};
    --mysteriousGrey: ${(props) => props.mysteriousGrey};
    --mysteriousGreyLighten: ${(props) => lighten(0.1, props.mysteriousGrey)};
    --mysteriousGreyDarken: ${(props) => darken(0.1, props.mysteriousGrey)};
    --closedGray: ${(props) => props.closedGray};
    --closedGrayLighten: ${(props) => lighten(0.1, props.closedGray)};
    --closedGrayOpacity: ${(props) => props.closedGray}78;
    --asphaltGray: ${(props) => props.asphaltGray};
    --asphaltGrayLighten: ${(props) => lighten(0.1, props.asphaltGray)};
    --asphaltGrayDarken: ${(props) => darken(0.1, props.asphaltGray)};
    --asphaltGrayOpacity: ${(props) => props.asphaltGray}80;
    --coal: ${(props) => props.coal};
    --coalDarken: ${(props) => darken(0.03, props.coal)};
    --midnight: #18191a;
    --midnightLighten: ${(props) => lighten(0.01, props.midnight)};
    --midnightDarken: ${(props) => darken(0.1, props.midnight)};
    --midnightOpacity: ${(props) => props.midnight}55;
    --error: ${(props) => props.error};
    --warning: ${(props) => props.warning};
    --success: ${(props) => props.success};
    --skeletonTransparent: ${(props) => props.skeletonTransparent};
    --skeletonQuarter: ${(props) => props.skeletonQuarter};
    --skeletonMiddle: ${(props) => props.skeletonMiddle};
    --skeletonFull: ${(props) => props.skeletonFull};
  }

  ::-webkit-scrollbar {
    background-color: var(--midnight);
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: var(--asphaltGray);
    :hover{
      background: var(--asphaltGrayLighten);
    }
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html{
    background: var(--coal);
    background: linear-gradient(90deg, var(--coal) 0%, var(--coal) 6.9%, var(--midnight) 6.9%, var(--midnight) 100%)
  }

  body{
    -webkit-font-smoothing: antialiased;
    overflow-y: scroll;
    background-color: transparent;

  }

  body, input, button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: var(--ultraWhite);
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500
  }

  button {
    cursor: pointer;
  }

  button:focus{
    outline: none;
    box-shadow: none !important;
  }

  .b-radius{
    border-radius: 20px;
  }

.w-35{
  width: 35%;
}

.w-40{
  width: 40%;
}

  .width-add-card{

        width: 25px;

  }

  .font-weight-300{
    font-weight: 300!important;
  }

  .font-weight-400{
    font-weight: 400;
  }

  .font-weight-600{
    font-weight: 600!important;
  }

  .new-card {
    color: var(--grayImmensity) !important;
  }

  .close-button {
    background-color: transparent;
    border: none;
    z-index: 999;
  }

  .payment-color{
    color: var(--graniteGray);
  }

  .modal-dialog {
    /* height: 100%; */
    display: flex;
    align-items: center;
}

  .justify-content-space-evenly{
    justify-content: space-evenly;
  }
  .text-gray-stroke {
    color: var(--deepGrey);
  }

  .border-silver {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(0deg, var(--asphaltGray), var(--asphaltGray)),
      linear-gradient(180deg, #4A4B4B 0%, #c3c3c3 100%);
      background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    color: var(--blizzard);

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  .remove-cards-button {
    button {
      border: none;
      border-radius: 15px;
    }
    button:nth-child(1){
      background-color: #373737;
      color:var(--blizzard);
    }
    button:nth-child(2){
      background-color: var(--technologicalGray);
      color:var(--midnight);
    }
  }


  .gray-button{
    background-color: var(--mysteriousGrey) !important;
    color: var(--blizzard);
    border-radius: 15px;
    border: none;
    :hover{
      background-color: var(--mysteriousGreyLighten) !important;
      color: var(--blizzardLighten);
      transition: 0.5s;
    }
  }

  .bg-btn.active{
    background-color: var(--greyMineral);
  }

  .profilePhoto {
    padding: 3px;
    background-image: linear-gradient(0deg, var(--coal), var(--coal)),
      radial-gradient(
        circle at top left,
        var(--blizzardOpacity),
        var(--blizzardOpacity)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 60px;
  }

  a.border-silver:hover, a.border-silver.active, button.border-silver:hover, button.border-silver.active {
    background-image: linear-gradient(180deg, #4A4B4B, #c3c3c3),
    linear-gradient(180deg, #4A4B4B, #c3c3c3);
    color: var(--ultraWhite);
    text-decoration: none;
  }

  .border-gray {
    cursor: pointer;
    border: none;
    padding: 1px;
    background-image: linear-gradient(180deg, var(--asphaltGray), var(--asphaltGray)),
      linear-gradient(180deg, var(--graniteGray), var(--graniteGray));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    transition-duration: 0.3s;
    color: var(--ultraWhite);

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  .card-exemple{
    border: solid 2px var(--technologicalGray);
    border-radius:20px;
    background-color: #fafbfc;
    p{
      font-size: 12px;
      line-height: 14px;
      span{
        color: var(--greyMineral)!important;

      }
    }
    img{
      border-radius: 0 0 20px 20px;
    }
  }


  .bg-payment-cards {
    background-color: var(--closedGray);
    border-radius: 12px;
  }

  .payment-button {
    border: none;
    background-color: var(--greyMineral);
    border-radius: 12px;
    color: var(--graniteGray);
  }

  a.border-gray:hover:not([disabled]), a.border-gray.active, button.border-gray:hover:not([disabled]), button.border-gray.active {
    background-image: linear-gradient(180deg, var(--greyMineral), var(--greyMineral)),
    linear-gradient(180deg, var(--greyMineral), var(--greyMineral));
    color: var(--ultraWhite);
    text-decoration: none;
  }

  a.border-gray:disabled, button.border-gray:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .modal-credit-card {
    background-color: var(--midnightOpacity) !important;
    .modal-content {
      border-radius: 20px;
      .close{
        span{
          color: var(--ultraWhite);
        }
      }
      background-color: var(--coal) !important;
    }
  }

  .modal-backdrop.show {
    opacity: 0.7!important;
}



  .border-credit-card{
    .modal-content {
      background: var(--coal);
      box-shadow: 19px 49px 112px var(--midnightOpacity),
        inset 1px 1px 2px var(--deepGreyOpacity),
        inset -1px -1px 2px var(--asphaltGrayOpacity);
      border-radius: 20px;
    }
  }

  .color-course-not-found{
      color:var(--greyMineral);
    }

  .modal-binance-pay, .modal-cripto-method, .modal-terms, .modal-tether {
    background-color: var(--midnightOpacity) !important;


    .modal-content {
      background: var(--coal);
      box-shadow: 19px 49px 112px var(--midnightOpacity),
        inset 1px 1px 2px var(--deepGreyOpacity),
        inset -1px -1px 2px var(--asphaltGrayOpacity);
      border-radius: 20px;
      h4,
      span {
        color: #C4C4C4;
      }
      p{
        color:var(--greyMineral);
      }

      .modal-binance-btn{
        background-color: #FCD535;
        border-radius: 12px;
      }

      .close{
        span{
          color: var(--ultraWhite);
        }
      }

      .qr-code-binance{
        padding: 9px;
        background-origin: border-box !important;
        background-clip: content-box, border-box !important;
        border-image-slice: 1 !important;
        border-radius: 22px;
        background-image:
          linear-gradient(180deg, #323336, #323336),
          linear-gradient(270.04deg, #FB5A43 0.03%, #C341AE 30.44%, #9445C2 57.35%, #079CFE 95.73%);
        width: 100%;
        max-width: 300px;
      }

      .old-price {
        color: var(--error);
      }
    }

    .img-icon{
      width: 25px;
      height: 25px;
    }

    .font-size-50{
      font-size: 50%;
    }

    .payment-button{
      background: var(--asphaltGray);
      border: 2px solid #464343;
      box-sizing: border-box;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px 40px;
      margin: auto;

      :hover {
      background-color: var(--greyMineralDarken);
      transition: 0.5s;
    }

      img{
        max-width: 100px;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .btn-submit-tether{
      background: var(--technologicalGray);
      border-radius: 15px;
      color: var(--ultraWhite);
      border: 0;
      width: 100%;
      padding: 10px 0;
      height: 54px;
      margin-top: 35px;
    }

    .tether-box{
      background: var(--coal);
      box-shadow: 0px 35px 25px var(--midnightOpacity),
      inset 1px 1px 2px var(--deepGreyOpacity),
      inset -1px -1px 2px var(--asphaltGrayOpacity);
      border-radius: 37px;

      .btn-copy {
        background-color: transparent;
        border: solid 1.5px var(--grayImmensity);
        border-radius: 15px;
        height: 54px;
        color: var(--graniteGray);
        font-weight: 300;
        width: 100%;
      }

      .btn-confirm{
        background: var(--technologicalGray);
        border-radius: 15px;
        color: var(--ultraWhite);
        border: 0;
        width: 100%;
        padding: 10px 0;
        height: 54px;
        margin-top: 35px;
      }
    }
  }

  .modal-quizzes{
    .modal-content {
      background: var(--coal);
      box-shadow: 19px 49px 112px var(--midnightOpacity),
        inset 1px 1px 2px var(--deepGreyOpacity),
        inset -1px -1px 2px var(--asphaltGrayOpacity);
      border-radius: 20px;
      .close{
        span{
          color: var(--ultraWhite);
        }
      }


      .bar {
        width: 30%;
        height: 4px;
        border-radius: 7px;
        background-color: #888888;
        margin-bottom: 5px;

        div {
          border-radius: 7px;
          background: linear-gradient(
            205.37deg,
            rgba(168, 107, 15, 1) 6.7%,
            rgba(242, 201, 76, 1) 86.13%
          );
          height: calc(100% + 2px);
          top: -1px;
          width: 80%;
        }
      }
    }
  }

  .modal-info{
    .modal-content {
      background-color: var(--midnight) !important;

      .info {
        background: var(--asphaltGray);
        border: 1px solid var(--closedGray);
        border-radius: 20px;

        a {
          color: var(--ultraWhite);
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }

  .modal-payment{
    .modal-content {
      .close{
        span{
          color: var(--ultraWhite);
        }
      }
      background-color: var(--midnight) !important;
    }

    .hidden {
      display: none;
    }

    #card-error {
      color: rgb(105, 115, 134);
      font-size: 16px;
      line-height: 20px;
      margin-top: 12px;
      text-align: center;
    }

    #card-element {
      background: #232129;
      border-radius: 10px;
      border: 1.5px solid rgba(224, 224, 224, 0.8);
      padding: 10px;
      width: 100%;
      max-height: 44px;
    }

    #payment-request-button {
      margin-bottom: 32px;
    }

    .card.border-silver:hover{
      background-image: linear-gradient(0deg, var(--asphaltGray), var(--asphaltGray)),
      linear-gradient(180deg, #4A4B4B 0%, #c3c3c3 100%);
    }

    .card.border-golden:hover{
      background-image: linear-gradient(180deg, var(--asphaltGray), var(--asphaltGray)),
      linear-gradient(180deg, rgba(168, 107, 15), rgba(242, 201, 76, 1));
    }

    .error{
      color: var(--error);
    }

    .credit-card {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;

      > div {
        width: 50% !important;
        padding: 0 10px;
      }

      .credit-card-data {
        > div {
          width: 100%;
        }
      }
    }

    /* Buttons and links */
    button.border-golden {
      width: 100%;
    }

    button:hover {
      filter: contrast(115%);
    }

    button:disabled {
      opacity: 0.5;
      cursor: default;
    }

    /* spinner/processing state, errors */
    .spinner,
    .spinner:before,
    .spinner:after {
      border-radius: 50%;
    }

    .spinner {
      color: var(--ultraWhite);
      font-size: 22px;
      text-indent: -99999px;
      margin: 0px auto;
      position: relative;
      width: 20px;
      height: 20px;
      box-shadow: inset 0 0 0 2px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
    }

    .spinner:before,
    .spinner:after {
      position: absolute;
      content: "";
    }

    .spinner:before {
      width: 10.4px;
      height: 20.4px;
      background: transparent;
      border-radius: 20.4px 0 0 20.4px;
      top: -0.2px;
      left: -0.2px;
      -webkit-transform-origin: 10.4px 10.2px;
      transform-origin: 10.4px 10.2px;
      -webkit-animation: loading 2s infinite ease 1.5s;
      animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
      width: 10px;
      height: 10px;
      background: var(--ultraWhite);
      border-radius: 50%;
      top: -0.1px;
      left: 10.2px;
      -webkit-transform-origin: 0px 10.2px;
      transform-origin: 0px 10.2px;
      -webkit-animation: loading 2s infinite ease;
      animation: loading 2s infinite ease;
    }

    @keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  .modal-introduction{
    .modal-content {
      background: #17171a;
      box-shadow: 19px 49px 112px var(--midnightOpacity),
        inset 1px 1px 2px var(--deepGreyOpacity),
        inset -1px -1px 2px var(--asphaltGrayOpacity);
      border-radius: 20px;}

    .video-size{
      height: 500px;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .loading-box {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: var(--midnightOpacity);

    .spinner-size{
      width: 32px;
      height: 32px;
    }
  }




  @media screen and (max-width: 991px){
    .font-size-78{
      font-size: 78%;
    }
    .horizontal-scroolbar{
      overflow: auto;
      white-space: nowrap;
      ::-webkit-scrollbar {
        display:none;
      }

    }
    .modal-payment{
      .credit-card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;

        > div {
          width: 100% !important;
          padding: 0;
        }

        .credit-card-data {
          > div {
            width: 100%;
          }
        }
      }
    }


    .modal-introduction{
      .video-size{
        height: 250px;
      }
    }
  }

  @media screen and (min-width: 992px){
    .max-width-lg{
    max-width: 940px!important;
    }
  }

  .rec-pagination {
    .rec-dot {
      box-shadow: 0 0 1px 2px var(--blizzardOpacity) !important;
    }

    .rec-dot_active {
      background-color: var(--blizzardOpacity) !important;
      box-shadow: 0 0 1px 3px var(--blizzard) !important;
    }
  }

  .slick-prev,
  .slick-next {
      z-index: 1;

      ::before{
        background-color: transparent;
      }
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  // inicio estlilização datepicker

  .react-datepicker {
    border-radius: 15px !important;
  }

  .react-datepicker__month-container {
    background: #17171a !important;
    border-radius: 15px !important;
  }

  .react-datepicker__header {
    background: #17171a !important;
    border-radius: 15px 15px 0 0 !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: var(--graniteGray) !important;
  }

  .react-datepicker__month {
    border-radius: 15px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: var(--graniteGray) !important;
    :hover {
      color: #17171a !important;
    }
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    color: var(--graniteGray) !important;
    background: #17171a !important;
  }

  // fim estilização datepicker

  // estilos complementares do BS4
  body{
    .p-relative{
      position: relative;
    }

    .p-absolute{
      position: absolute;
    }

    .h1, .h2, .h3, .h4, .h5, .h6,
    .h1-sm, .h2-sm, .h3-sm, .h4-sm, .h5-sm, .h6-sm,
    .h1-md, .h2-md, .h3-md, .h4-md, .h5-md, .h6-md,
    .h1-lg, .h2-lg, .h3-lg, .h4-lg, .h5-lg, .h6-lg,
    .h1-xl, .h2-xl, .h3-xl, .h4-xl, .h5-xl, .h6-xl,
    .h1-xxl, .h2-xxl, .h3-xxl, .h4-xxl, .h5-xxl, .h6-xxl {
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .display-1 {
      font-size: 6rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-2 {
      font-size: 5.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-3 {
      font-size: 4.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-4 {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .h1 {
      font-size: 2.5rem;
    }

    .h2 {
      font-size: 2rem;
    }

    .h3 {
      font-size: 1.75rem;
    }

    .h4 {
      font-size: 1.5rem;
    }

    .h5 {
      font-size: 1.25rem;
    }

    .h6 {
      font-size: 1rem;
    }

    .w-8{
      width: 8%;
    }

    .w-25 {
      width: 25% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-60 {
      width: 60% !important;
    }

    .w-65 {
      width: 65%!important;
    }

    .w-75 {
      width: 75% !important;
    }

    .w-90 {
      width: 90% !important;
    }

    .w-100 {
      width: 100% !important;
    }

    .w-auto {
      width: auto !important;
    }

    .h-25 {
      height: 25% !important;
    }

    .h-50 {
      height: 50% !important;
    }

    .h-75 {
      height: 75% !important;
    }

    .h-100 {
      height: 100% !important;
    }

    .h-auto {
      height: auto !important;
    }

    .mw-100 {
      max-width: 100% !important;
    }

    .mh-100 {
      max-height: 100% !important;
    }

    .min-vw-100 {
      min-width: 100vw !important;
    }

    .min-vh-100 {
      min-height: 100vh !important;
    }

    .vw-100 {
      width: 100vw !important;
    }

    .vh-100 {
      height: 100vh !important;
    }

    .small {
      font-size: 90%;
      font-weight: 400;
    }

    .normal{
      font-size: 100%;
    }

    .big{
      font-size: 120%;
    }

    @media screen and (min-width: 576px){
      .p-sm-relative{
        position: relative;
      }

      .p-sm-absolute{
        position: absolute;
      }

      .display-sm-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-sm {
        font-size: 2.5rem;
      }

      .h2-sm {
        font-size: 2rem;
      }

      .h3-sm {
        font-size: 1.75rem;
      }

      .h4-sm {
        font-size: 1.5rem;
      }

      .h5-sm {
        font-size: 1.25rem;
      }

      .h6-sm {
        font-size: 1rem;
      }

      .w-sm-25 {
        width: 25% !important;
      }

      .w-sm-50 {
        width: 50% !important;
      }

      .w-sm-75 {
        width: 75% !important;
      }

      .w-sm-100 {
        width: 100% !important;
      }

      .w-sm-auto {
        width: auto !important;
      }

      .h-sm-25 {
        height: 25% !important;
      }

      .h-sm-50 {
        height: 50% !important;
      }

      .h-sm-75 {
        height: 75% !important;
      }

      .h-sm-100 {
        height: 100% !important;
      }

      .h-sm-auto {
        height: auto !important;
      }

      .mw-sm-100 {
        max-width: 100% !important;
      }

      .mh-sm-100 {
        max-height: 100% !important;
      }

      .min-vw-sm-100 {
        min-width: 100vw !important;
      }

      .min-vh-sm-100 {
        min-height: 100vh !important;
      }

      .vw-sm-100 {
        width: 100vw !important;
      }

      .vh-sm-100 {
        height: 100vh !important;
      }

      .small-sm {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-sm {
        font-size: 100%;
      }

      .big-sm {
        font-size: 120%;
      }
    }

    @media screen and (max-width: 575px){
      .ReactCreditCard__container {
        width: 90%!important;

      }

      .ReactCreditCard {
        min-width: 290px!important;
      }

      .ReactCreditCard__number {
        font-size: 21px;
      }

      .ReactCreditCard__name {
        font-size: 14px;
      }
    }

    @media screen and (min-width: 768px){
      .font-weight-md-bold{
        font-weight: bold !important;
      }
      .mobile-only{
        display: none;
      }

      .desk-only{
        display: block;
      }

      .p-md-relative{
        position: relative;
      }

      .p-md-absolute{
        position: absolute;
      }

      .display-md-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-md {
        font-size: 2.5rem;
      }

      .h2-md {
        font-size: 2rem;
      }

      .h3-md {
        font-size: 1.75rem;
      }

      .h4-md {
        font-size: 1.5rem;
      }

      .h5-md {
        font-size: 1.25rem;
      }

      .h6-md {
        font-size: 1rem;
      }

      .w-md-25 {
        width: 25% !important;
      }

      .w-md-50 {
        width: 50% !important;
      }

      .w-md-75 {
        width: 75% !important;
      }

      .w-md-100 {
        width: 100% !important;
      }

      .w-md-auto {
        width: auto !important;
      }

      .h-md-25 {
        height: 25% !important;
      }

      .h-md-50 {
        height: 50% !important;
      }

      .h-md-75 {
        height: 75% !important;
      }

      .h-md-100 {
        height: 100% !important;
      }

      .h-md-auto {
        height: auto !important;
      }

      .mw-md-100 {
        max-width: 100% !important;
      }

      .mh-md-100 {
        max-height: 100% !important;
      }

      .min-vw-md-100 {
        min-width: 100vw !important;
      }

      .min-vh-md-100 {
        min-height: 100vh !important;
      }

      .vw-md-100 {
        width: 100vw !important;
      }

      .vh-md-100 {
        height: 100vh !important;
      }

      .small-md {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-md {
        font-size: 100%;
      }

      .big-md {
        font-size: 120%;
      }
    }

    @media screen and (min-width: 992px){
      .p-lg-relative{
        position: relative;
      }

      .p-lg-absolute{
        position: absolute;
      }

      .display-lg-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-lg {
        font-size: 2.5rem;
      }

      .h2-lg {
        font-size: 2rem;
      }

      .h3-lg {
        font-size: 1.75rem;
      }

      .h4-lg {
        font-size: 1.5rem;
      }

      .h5-lg {
        font-size: 1.25rem;
      }

      .h6-lg {
        font-size: 1rem;
      }

      .w-lg-25 {
        width: 25% !important;
      }

      .w-lg-50 {
        width: 50% !important;
      }

      .w-lg-75 {
        width: 75% !important;
      }

      .w-lg-100 {
        width: 100% !important;
      }

      .w-lg-auto {
        width: auto !important;
      }

      .h-lg-25 {
        height: 25% !important;
      }

      .h-lg-50 {
        height: 50% !important;
      }

      .h-lg-75 {
        height: 75% !important;
      }

      .h-lg-100 {
        height: 100% !important;
      }

      .h-lg-auto {
        height: auto !important;
      }

      .mw-lg-100 {
        max-width: 100% !important;
      }

      .mh-lg-100 {
        max-height: 100% !important;
      }

      .min-vw-lg-100 {
        min-width: 100vw !important;
      }

      .min-vh-lg-100 {
        min-height: 100vh !important;
      }

      .vw-lg-100 {
        width: 100vw !important;
      }

      .vh-lg-100 {
        height: 100vh !important;
      }

      .small-lg {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-lg {
        font-size: 100%;
      }

      .big-lg {
        font-size: 120%;
      }
    }

    @media screen and (min-width: 1200px){
      .p-xl-relative{
        position: relative;
      }

      .p-xl-absolute{
        position: absolute;
      }

      .display-xl-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-xl {
        font-size: 2.5rem;
      }

      .h2-xl {
        font-size: 2rem;
      }

      .h3-xl {
        font-size: 1.75rem;
      }

      .h4-xl {
        font-size: 1.5rem;
      }

      .h5-xl {
        font-size: 1.25rem;
      }

      .h6-xl {
        font-size: 1rem;
      }

      .w-xl-25 {
        width: 25% !important;
      }

      .w-xl-50 {
        width: 50% !important;
      }

      .w-xl-75 {
        width: 75% !important;
      }

      .w-xl-100 {
        width: 100% !important;
      }

      .w-xl-auto {
        width: auto !important;
      }

      .h-xl-25 {
        height: 25% !important;
      }

      .h-xl-50 {
        height: 50% !important;
      }

      .h-xl-75 {
        height: 75% !important;
      }

      .h-xl-100 {
        height: 100% !important;
      }

      .h-xl-auto {
        height: auto !important;
      }

      .mw-xl-100 {
        max-width: 100% !important;
      }

      .mh-xl-100 {
        max-height: 100% !important;
      }

      .min-vw-xl-100 {
        min-width: 100vw !important;
      }

      .min-vh-xl-100 {
        min-height: 100vh !important;
      }

      .vw-xl-100 {
        width: 100vw !important;
      }

      .vh-xl-100 {
        height: 100vh !important;
      }

      .small-xl {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-xl {
        font-size: 100%;
      }

      .big-xl {
        font-size: 120%;
      }
    }

    @media screen and (min-width: 1400px){
      .modal-dialog.modal-lg {
        width: 100%;
      }

      .d-xxl-none{
        display: none !important;
      }

      .d-xxl-block{
        display: block !important;
      }

      .d-xxl-flex{
        display: flex !important;
      }

      .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px !important;
      }

      .col-xxl-1,
      .col-xxl-2,
      .col-xxl-3,
      .col-xxl-4,
      .col-xxl-5,
      .col-xxl-6,
      .col-xxl-7,
      .col-xxl-8,
      .col-xxl-9,
      .col-xxl-10,
      .col-xxl-11,
      .col-xxl-12,
      .col-xxl,
      .col-xxl-auto {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }

      .col-xxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
      .col-xxl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }
      .col-xxl-1 {
        -ms-flex: 0 0 8.343434343434%;
        flex: 0 0 8.343434343434%;
        max-width: 8.343434343434%;
      }
      .col-xxl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .col-xxl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col-xxl-4 {
        -ms-flex: 0 0 33.343434343434%;
        flex: 0 0 33.343434343434%;
        max-width: 33.343434343434%;
      }
      .col-xxl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
      .col-xxl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-xxl-7 {
        -ms-flex: 0 0 58.343434343434%;
        flex: 0 0 58.343434343434%;
        max-width: 58.343434343434%;
      }
      .col-xxl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      .col-xxl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }
      .col-xxl-10 {
        -ms-flex: 0 0 83.343434343434%;
        flex: 0 0 83.343434343434%;
        max-width: 83.343434343434%;
      }
      .col-xxl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
      .col-xxl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .p-xxl-relative{
        position: relative;
      }

      .p-xxl-absolute{
        position: absolute;
      }

      .display-xxl-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-xxl {
        font-size: 2.5rem;
      }

      .h2-xxl {
        font-size: 2rem;
      }

      .h3-xxl {
        font-size: 1.75rem;
      }

      .h4-xxl {
        font-size: 1.5rem;
      }

      .h5-xxl {
        font-size: 1.25rem;
      }

      .h6-xxl {
        font-size: 1rem;
      }

      .w-xxl-25 {
        width: 25% !important;
      }

      .w-xxl-50 {
        width: 50% !important;
      }

      .w-xxl-75 {
        width: 75% !important;
      }

      .w-xxl-100 {
        width: 100% !important;
      }

      .w-xxl-auto {
        width: auto !important;
      }

      .h-xxl-25 {
        height: 25% !important;
      }

      .h-xxl-50 {
        height: 50% !important;
      }

      .h-xxl-75 {
        height: 75% !important;
      }

      .h-xxl-100 {
        height: 100% !important;
      }

      .h-xxl-auto {
        height: auto !important;
      }

      .mw-xxl-100 {
        max-width: 100% !important;
      }

      .mh-xxl-100 {
        max-height: 100% !important;
      }

      .min-vw-xxl-100 {
        min-width: 100vw !important;
      }

      .min-vh-xxl-100 {
        min-height: 100vh !important;
      }

      .vw-xxl-100 {
        width: 100vw !important;
      }

      .vh-xxl-100 {
        height: 100vh !important;
      }

      .small-xxl {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-xxl {
        font-size: 100%;
      }

      .big-xxl {
        font-size: 120%;
      }

      .d-xxl-flex{
        display: flex;
      }

      .justify-content-xxl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
      }
      .justify-content-xxl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
      }
      .justify-content-xxl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
      }
      .justify-content-xxl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
      }
      .justify-content-xxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
      }
      .align-items-xxl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
      }
      .align-items-xxl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
      }
      .align-items-xxl-center {
        -ms-flex-align: center !important;
        align-items: center !important;
      }
      .align-items-xxl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
      }
      .align-items-xxl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
      }
      .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
      }
      .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
      }
      .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
      }
      .align-content-xxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
      }
      .align-content-xxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
      }
      .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
      }
      .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
      }
      .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
      }
      .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
      }
      .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
      }
      .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
      }
      .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
      }

      .m-xxl-0 {
        margin: 0 !important;
      }
      .mt-xxl-0,
      .my-xxl-0 {
        margin-top: 0 !important;
      }
      .mr-xxl-0,
      .mx-xxl-0 {
        margin-right: 0 !important;
      }
      .mb-xxl-0,
      .my-xxl-0 {
        margin-bottom: 0 !important;
      }
      .ml-xxl-0,
      .mx-xxl-0 {
        margin-left: 0 !important;
      }
      .m-xxl-1 {
        margin: 0.25rem !important;
      }
      .mt-xxl-1,
      .my-xxl-1 {
        margin-top: 0.25rem !important;
      }
      .mr-xxl-1,
      .mx-xxl-1 {
        margin-right: 0.25rem !important;
      }
      .mb-xxl-1,
      .my-xxl-1 {
        margin-bottom: 0.25rem !important;
      }
      .ml-xxl-1,
      .mx-xxl-1 {
        margin-left: 0.25rem !important;
      }
      .m-xxl-2 {
        margin: 0.5rem !important;
      }
      .mt-xxl-2,
      .my-xxl-2 {
        margin-top: 0.5rem !important;
      }
      .mr-xxl-2,
      .mx-xxl-2 {
        margin-right: 0.5rem !important;
      }
      .mb-xxl-2,
      .my-xxl-2 {
        margin-bottom: 0.5rem !important;
      }
      .ml-xxl-2,
      .mx-xxl-2 {
        margin-left: 0.5rem !important;
      }
      .m-xxl-3 {
        margin: 1rem !important;
      }
      .mt-xxl-3,
      .my-xxl-3 {
        margin-top: 1rem !important;
      }
      .mr-xxl-3,
      .mx-xxl-3 {
        margin-right: 1rem !important;
      }
      .mb-xxl-3,
      .my-xxl-3 {
        margin-bottom: 1rem !important;
      }
      .ml-xxl-3,
      .mx-xxl-3 {
        margin-left: 1rem !important;
      }
      .m-xxl-4 {
        margin: 1.5rem !important;
      }
      .mt-xxl-4,
      .my-xxl-4 {
        margin-top: 1.5rem !important;
      }
      .mr-xxl-4,
      .mx-xxl-4 {
        margin-right: 1.5rem !important;
      }
      .mb-xxl-4,
      .my-xxl-4 {
        margin-bottom: 1.5rem !important;
      }
      .ml-xxl-4,
      .mx-xxl-4 {
        margin-left: 1.5rem !important;
      }
      .m-xxl-5 {
        margin: 3rem !important;
      }
      .mt-xxl-5,
      .my-xxl-5 {
        margin-top: 3rem !important;
      }
      .mr-xxl-5,
      .mx-xxl-5 {
        margin-right: 3rem !important;
      }
      .mb-xxl-5,
      .my-xxl-5 {
        margin-bottom: 3rem !important;
      }
      .ml-xxl-5,
      .mx-xxl-5 {
        margin-left: 3rem !important;
      }
      .p-xxl-0 {
        padding: 0 !important;
      }
      .pt-xxl-0,
      .py-xxl-0 {
        padding-top: 0 !important;
      }
      .pr-xxl-0,
      .px-xxl-0 {
        padding-right: 0 !important;
      }
      .pb-xxl-0,
      .py-xxl-0 {
        padding-bottom: 0 !important;
      }
      .pl-xxl-0,
      .px-xxl-0 {
        padding-left: 0 !important;
      }
      .p-xxl-1 {
        padding: 0.25rem !important;
      }
      .pt-xxl-1,
      .py-xxl-1 {
        padding-top: 0.25rem !important;
      }
      .pr-xxl-1,
      .px-xxl-1 {
        padding-right: 0.25rem !important;
      }
      .pb-xxl-1,
      .py-xxl-1 {
        padding-bottom: 0.25rem !important;
      }
      .pl-xxl-1,
      .px-xxl-1 {
        padding-left: 0.25rem !important;
      }
      .p-xxl-2 {
        padding: 0.5rem !important;
      }
      .pt-xxl-2,
      .py-xxl-2 {
        padding-top: 0.5rem !important;
      }
      .pr-xxl-2,
      .px-xxl-2 {
        padding-right: 0.5rem !important;
      }
      .pb-xxl-2,
      .py-xxl-2 {
        padding-bottom: 0.5rem !important;
      }
      .pl-xxl-2,
      .px-xxl-2 {
        padding-left: 0.5rem !important;
      }
      .p-xxl-3 {
        padding: 1rem !important;
      }
      .pt-xxl-3,
      .py-xxl-3 {
        padding-top: 1rem !important;
      }
      .pr-xxl-3,
      .px-xxl-3 {
        padding-right: 1rem !important;
      }
      .pb-xxl-3,
      .py-xxl-3 {
        padding-bottom: 1rem !important;
      }
      .pl-xxl-3,
      .px-xxl-3 {
        padding-left: 1rem !important;
      }
      .p-xxl-4 {
        padding: 1.5rem !important;
      }
      .pt-xxl-4,
      .py-xxl-4 {
        padding-top: 1.5rem !important;
      }
      .pr-xxl-4,
      .px-xxl-4 {
        padding-right: 1.5rem !important;
      }
      .pb-xxl-4,
      .py-xxl-4 {
        padding-bottom: 1.5rem !important;
      }
      .pl-xxl-4,
      .px-xxl-4 {
        padding-left: 1.5rem !important;
      }
      .p-xxl-5 {
        padding: 3rem !important;
      }
      .pt-xxl-5,
      .py-xxl-5 {
        padding-top: 3rem !important;
      }
      .pr-xxl-5,
      .px-xxl-5 {
        padding-right: 3rem !important;
      }
      .pb-xxl-5,
      .py-xxl-5 {
        padding-bottom: 3rem !important;
      }
      .pl-xxl-5,
      .px-xxl-5 {
        padding-left: 3rem !important;
      }
      .m-xxl-n1 {
        margin: -0.25rem !important;
      }
      .mt-xxl-n1,
      .my-xxl-n1 {
        margin-top: -0.25rem !important;
      }
      .mr-xxl-n1,
      .mx-xxl-n1 {
        margin-right: -0.25rem !important;
      }
      .mb-xxl-n1,
      .my-xxl-n1 {
        margin-bottom: -0.25rem !important;
      }
      .ml-xxl-n1,
      .mx-xxl-n1 {
        margin-left: -0.25rem !important;
      }
      .m-xxl-n2 {
        margin: -0.5rem !important;
      }
      .mt-xxl-n2,
      .my-xxl-n2 {
        margin-top: -0.5rem !important;
      }
      .mr-xxl-n2,
      .mx-xxl-n2 {
        margin-right: -0.5rem !important;
      }
      .mb-xxl-n2,
      .my-xxl-n2 {
        margin-bottom: -0.5rem !important;
      }
      .ml-xxl-n2,
      .mx-xxl-n2 {
        margin-left: -0.5rem !important;
      }
      .m-xxl-n3 {
        margin: -1rem !important;
      }
      .mt-xxl-n3,
      .my-xxl-n3 {
        margin-top: -1rem !important;
      }
      .mr-xxl-n3,
      .mx-xxl-n3 {
        margin-right: -1rem !important;
      }
      .mb-xxl-n3,
      .my-xxl-n3 {
        margin-bottom: -1rem !important;
      }
      .ml-xxl-n3,
      .mx-xxl-n3 {
        margin-left: -1rem !important;
      }
      .m-xxl-n4 {
        margin: -1.5rem !important;
      }
      .mt-xxl-n4,
      .my-xxl-n4 {
        margin-top: -1.5rem !important;
      }
      .mr-xxl-n4,
      .mx-xxl-n4 {
        margin-right: -1.5rem !important;
      }
      .mb-xxl-n4,
      .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
      }
      .ml-xxl-n4,
      .mx-xxl-n4 {
        margin-left: -1.5rem !important;
      }
      .m-xxl-n5 {
        margin: -3rem !important;
      }
      .mt-xxl-n5,
      .my-xxl-n5 {
        margin-top: -3rem !important;
      }
      .mr-xxl-n5,
      .mx-xxl-n5 {
        margin-right: -3rem !important;
      }
      .mb-xxl-n5,
      .my-xxl-n5 {
        margin-bottom: -3rem !important;
      }
      .ml-xxl-n5,
      .mx-xxl-n5 {
        margin-left: -3rem !important;
      }
      .m-xxl-auto {
        margin: auto !important;
      }
      .mt-xxl-auto,
      .my-xxl-auto {
        margin-top: auto !important;
      }
      .mr-xxl-auto,
      .mx-xxl-auto {
        margin-right: auto !important;
      }
      .mb-xxl-auto,
      .my-xxl-auto {
        margin-bottom: auto !important;
      }
      .ml-xxl-auto,
      .mx-xxl-auto {
        margin-left: auto !important;
      }
    }
  }

  @media screen and (min-width: 1800px) {
    .p-5-1800 {
      padding: 3rem !important;
    }
  }

  @media screen and (min-width: 992px) {
    body .menu-header{
      width: 90px !important;
    }
  }

  @media screen and (max-width: 370px){
    .font-size-78 {
      font-size: 66%;
    }
  }

`;
