import React, { useCallback, useEffect, useState, useMemo } from 'react';

import api from '~/services/api';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import TableData, { IColumn } from '~/components/TableData';
import AddCreditCard from '~/components/AddCreditCard';
import RemoveCreditCard from '~/components/RemoveCreditCard';

import visa from '~/assets/icons/cc-visa-dark.svg';
import mastercard from '~/assets/icons/cc-mastercard-dark.svg';
import discover from '~/assets/icons/cc-discover-dark.svg';
import amex from '~/assets/icons/cc-amex-dark.svg';
import exclamation from '~/assets/icons/exclamation-icon.svg';
import wire from '~/assets/logotipos/wire.svg';
import copy from '~/assets/icons/copy.svg';

interface IPaymentOptionsProps {
  active: boolean;
}

interface IApiCreditCard {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  address: string;
  primary_card: boolean;
}

interface IReponseCreditCard {
  id: string;
  name: string;
  number: string;
  expiration: string;
  flag: string;
  primary_card: boolean;
}

const PaymentOptions: React.FC<IPaymentOptionsProps> = ({ active }) => {
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [creditCards, setCreditCards] = useState<IReponseCreditCard[]>([]);
  const [activeAddCard, setActiveAddCard] = useState(false);

  useEffect(() => {
    api.get('payments/customers/credit-cards').then((response) => {
      const data = response.data.map((card: IApiCreditCard) => {
        return {
          id: card.id,
          name: card.name,
          number: `****-${card.number}`,
          expiration: card.expirity,
          flag: card.brand,
          primary_card: card.primary_card,
        };
      });
      setCreditCards(data);
      setLoading(false);
    });
  }, []);

  const handleCreatedCard = useCallback(
    async (data) => {
      const creditCard = data;
      setActiveAddCard(true);
      if (creditCards.length === 0) {
        await api.patch(`payments/customers/credit-cards/${creditCard.id}`);
        creditCard.primary_card = true;
      }
      setCreditCards((state) => [...state, creditCard]);
      setActiveAddCard(false);
    },
    [creditCards.length]
  );

  const handleDeleteCreditCard = useCallback(
    async (card: IReponseCreditCard) => {
      const newCreditCardsList = creditCards.slice();
      if (card.primary_card && creditCards.length > 1) {
        if (card === creditCards[0]) {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[1].id}`
          );
          newCreditCardsList[1].primary_card = true;
        } else {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[0].id}`
          );
          newCreditCardsList[0].primary_card = true;
        }
      }
      const cards = newCreditCardsList.filter(
        (creditCard) => creditCard.id !== card.id
      );
      setCreditCards(cards);
    },
    [creditCards]
  );

  const handleChangePrimaryCard = useCallback(
    async (e) => {
      const newCreditCardsList = creditCards.slice();
      const primaryCardIndex = creditCards.findIndex(
        (creditCard) => !!creditCard.primary_card
      );
      if (primaryCardIndex >= 0) {
        newCreditCardsList[primaryCardIndex].primary_card = false;
      }

      const cardIndex = creditCards.findIndex(
        (creditCard) => creditCard.id === e.target.value
      );
      if (cardIndex >= 0) {
        await api.patch(`payments/customers/credit-cards/${e.target.value}`);
        newCreditCardsList[cardIndex].primary_card = true;
      }
      setCreditCards(newCreditCardsList);
    },
    [creditCards]
  );

  const columns: IColumn[] = useMemo(
    () => [
      {
        name: '#',
        selector: '',
        className: 'text-center col-md-1',
        cellClassName:
          'd-flex d-md-block justify-content-between align-items-center text-left text-md-center col-md-1 mb-3 mb-lg-0',
        customCell: (_, index) => (
          <p className="mb-0 font-weight-400">{index + 1}</p>
        ),
      },
      {
        name: language.subscriptions_cards.th_1,
        selector: 'number',
        className: 'text-center col-md',
        cellClassName:
          'd-flex d-md-block justify-content-between align-items-center text-right text-md-center col-md mb-3 mb-lg-0',
        customCell: (row) => (
          <>
            <p className="mb-0 font-weight-400">{row.flag}</p>
            <p className="mb-0 font-weight-400">{row.number}</p>
          </>
        ),
      },
      {
        name: language.subscriptions_cards.th_2,
        selector: 'expiration',
        className: 'text-center col-md',
        cellClassName:
          'd-flex d-md-block justify-content-between align-items-center text-left text-md-center col-md mb-3 mb-lg-0 font-weight-400',
      },
      {
        name: language.subscriptions_cards.th_3,
        selector: 'name',
        className: 'text-center col-md',
        cellClassName:
          'd-flex d-md-block justify-content-between align-items-center text-right text-md-center col-md mb-3 mb-lg-0 font-weight-400',
      },
      {
        name: language.subscriptions_cards.th_5,
        selector: '',
        className: 'text-center col-md',
        cellClassName:
          'd-flex d-md-block justify-content-between align-items-center text-right text-md-center col-md mb-3 mb-lg-0',
        customCell: (row: any) => (
          <input
            type="checkbox"
            name="primary-card"
            value={row.id}
            onChange={handleChangePrimaryCard}
            checked={row.primary_card}
          />
        ),
      },
      {
        name: '',
        selector: '',
        className: 'text-center col-md-1',
        cellClassName: 'text-center col-md-1 text-right',
        customCell: (row: any) => (
          <RemoveCreditCard card={row} onRemoveCard={handleDeleteCreditCard} />
        ),
      },
    ],
    [
      handleChangePrimaryCard,
      handleDeleteCreditCard,
      language.subscriptions_cards.th_1,
      language.subscriptions_cards.th_2,
      language.subscriptions_cards.th_3,
      language.subscriptions_cards.th_5,
    ]
  );

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">
            <div className="d-flex flex-wrap flex-sm-nowrap align-items-center align-items-sm-end align-items-xl-center justify-content-between mb-3">
              <div className="d-flex flex-wrap flex-xl-now-wrap align-items-center w-100 w-sm-auto">
                <h2 className="h5 h4-md mb-0 mb-sm-2 mb-xl-0 w-100 w-sm-auto">
                  {language.subscriptions_cards.h2}
                </h2>
                <div className="d-flex justify-content-around justify-content-sm-start py-3 py-sm-0 mx-xl-2 w-100 w-sm-auto brand-group">
                  <img src={visa} alt="Visa" className="mx-1" />
                  <img src={mastercard} alt="Mastercard" className="mx-1" />
                  <img src={discover} alt="Discover" className="mx-1" />
                  <img src={amex} alt="Amex" className="mx-1" />
                </div>
              </div>
              <AddCreditCard
                active={activeAddCard}
                onCreatedCard={handleCreatedCard}
              />
            </div>
            {loading && (
              <div className="bg-skeleton">
                <div className="align-items-center text-center py-4 p-relative text-md-left px-4 px-xxl-5">
                  <div className="d-none d-md-flex flex-wrap justify-content-xl-around w-100 border-bottom pb-3">
                    <div className="text-center col-md-1">
                      <p className="skeleton h6">##</p>
                    </div>
                    <div className="text-center col-md">
                      <p className="skeleton h6">Card</p>
                    </div>
                    <div className="text-center col-md">
                      <p className="skeleton h6">Expiration Date</p>
                    </div>
                    <div className="text-center col-md">
                      <p className="skeleton h6">Name on Card</p>
                    </div>
                    <div className="text-center col-md">
                      <p className="skeleton h6">Primary Card</p>
                    </div>
                    <div className="text-center col-md-1">
                      <p className="skeleton h6" />
                    </div>
                  </div>
                  <div className="flex-wrap justify-content-xl-around w-100">
                    <div className="mt-3">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="d-flex d-md-block justify-content-between align-items-center text-left text-md-center col-md-1 mb-3 mb-lg-0">
                          <div className="d-md-none">
                            <p className="skeleton mb-0 font-weight-400">##</p>
                          </div>
                          <div>
                            <p className="skeleton mb-0 font-weight-400">##</p>
                          </div>
                        </div>
                        <div className="d-flex d-md-block justify-content-between align-items-center text-right text-md-center col-md mb-3 mb-lg-0">
                          <div className="d-md-none">
                            <p className="skeleton mb-0 font-weight-400">
                              Card
                            </p>
                          </div>
                          <div>
                            <p className="skeleton mb-0 font-weight-400">
                              MasterCard
                            </p>
                            <p className="skeleton mb-0 font-weight-400">
                              ****-****
                            </p>
                          </div>
                        </div>
                        <div className="d-flex d-md-block justify-content-between align-items-center text-left text-md-center col-md mb-3 mb-lg-0 font-weight-400">
                          <div className="d-md-none">
                            <p className="skeleton mb-0 font-weight-400">
                              Expiration Date
                            </p>
                          </div>
                          <div>
                            <p className="skeleton mb-0 font-weight-400">
                              08/2024
                            </p>
                          </div>
                        </div>
                        <div className="d-flex d-md-block justify-content-between align-items-center text-right text-md-center col-md mb-3 mb-lg-0 font-weight-400">
                          <div className="d-md-none">
                            <p className="skeleton mb-0 font-weight-400">
                              Name on Card
                            </p>
                          </div>
                          <div>
                            <p className="skeleton mb-0 font-weight-400">
                              ##### ##### #####
                            </p>
                          </div>
                        </div>
                        <div className="d-flex d-md-block justify-content-between align-items-center text-right text-md-center col-md mb-3 mb-lg-0">
                          <div className="d-md-none">
                            <p className="skeleton mb-0 font-weight-400">
                              Primary Card
                            </p>
                          </div>
                          <div>
                            <p className="skeleton mb-0">###</p>
                          </div>
                        </div>
                        <div className="text-center col-md-1 mb-3 mb-lg-0 text-right">
                          <div className="d-md-none">
                            <p className="skeleton mb-0 font-weight-400" />
                          </div>
                          <div>
                            <button
                              type="button"
                              className="skeleton border-0 bg-transparent"
                            >
                              ###
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <TableData
              data={creditCards}
              columns={columns}
              className={loading === true ? 'd-none' : ''}
            />
          </div>
          {false && (
            <>
              <div className="col-12 py-3">
                <h2 className="h5 h4-md mb-5">
                  Wire | Direct Deposit Payments
                </h2>
                <div className="bg-gray px-5">
                  <div className="d-md-flex align-items-cl-center text-center py-4 p-relative text-md-left">
                    <img
                      src={wire}
                      alt="Wiser"
                      className="w-25 w-md-auto my-2 my-md-0 mx-3 mx-xl-2 ddp"
                    />
                    <div className="d-flex flex-wrap justify-content-xl-around w-100">
                      <div className="w-100 w-md-50 w-xl-auto mx-xl-2">
                        <small className="font-weight-bold">Company Name</small>
                        <p>Wiser</p>
                      </div>
                      <div className="w-100 w-md-50 w-xl-auto mx-xl-2">
                        <small className="font-weight-bold">
                          Routing | ABA
                        </small>
                        <p>12340567</p>
                      </div>
                      <div className="w-100 w-md-50 w-xl-auto mx-xl-2">
                        <small className="font-weight-bold">Account #</small>
                        <p>66998989</p>
                      </div>
                      <div className="w-100 w-md-50 w-xl-auto mx-xl-2">
                        <small className="font-weight-bold">Swift Code</small>
                        <p className="mb-0">CHASU</p>
                        <p>
                          <small>International Wires</small>
                        </p>
                      </div>
                      <div className="w-100 w-md-50 w-xl-auto mx-xl-2">
                        <small className="font-weight-bold">Memo:</small>
                        <p className="mb-0">Product Name</p>
                        <p>
                          <small>Your Name & User Name</small>
                        </p>
                      </div>
                    </div>
                    <a href="/" className="p-absolute copy ">
                      <img src={copy} alt="copy" className="mr-2" /> copy
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                <h2 className="h5 h4-md mb-4 mt-5">
                  Certified Checks | Money Orders
                </h2>
                <div className="bg-gray py-2 px-5">
                  <div className="d-flex align-items-center">
                    <div className="row">
                      <div className="col-3 mx-2 py-md-3 px-2">
                        <small className="font-weight-bold">Company Name</small>
                      </div>
                      <div className="col-3 mx-2 py-md-3 px-2">
                        <small className="font-weight-bold">Address</small>
                      </div>
                      <div className="col-3 mx-2 py-md-3 px-2">
                        <small className="font-weight-bold">Memo:</small>
                      </div>
                      <div className="col-12">
                        <hr className="my-1" />
                      </div>
                      <div className="col-3 mx-2 py-md-3 px-2">
                        <p>Wiser</p>
                      </div>
                      <div className="col-3 mx-2 py-md-3 px-2">
                        <p className="mb-0">1234 Roader Lane</p>
                        <p>
                          <small>Costa Mesa, CA 92626 USA</small>
                        </p>
                      </div>
                      <div className="col-3 mx-2 py-md-3 px-2">
                        <p className="mb-0">Product Name</p>
                        <p>
                          <small>Your Name & User Name</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="alert-text px-3 px-md-5 px-xl-0">
                    <img src={exclamation} alt="Exclamation" />
                    <small className="ml-3">
                      <b>
                        To mail a certified check | cashiers check (must be a
                        bank check - no personal checks&nbsp;are&nbsp;accepted):
                      </b>
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                <h2 className="h5 h4-md mb-4 mt-5">Bank Transfer</h2>
                <div className="bg-gray py-2 px-5">
                  <div className="row align-items-center">
                    <div className="col-2">
                      <img src={wire} alt="wisser" />
                    </div>
                    <div className="col-10 p-relative">
                      <div className="row">
                        <div className="col-3 mx-2 py-md-3 px-2">
                          <small className="font-weight-bold">
                            Company Name
                          </small>
                          <p>Wiserr, Inc.</p>
                        </div>
                        <div className="col-3 mx-2 py-md-3 px-2">
                          <small className="font-weight-bold">Account #</small>
                          <p>66998989</p>
                        </div>
                        <div className="col-3 mx-2 py-md-3 px-2">
                          <small className="font-weight-bold">
                            Swift Code:
                          </small>
                          <p className="mb-0">CHASU</p>
                          <p className="mb-0">International Wires</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 mx-2 py-md-3 px-2">
                          <small className="font-weight-bold">Memo:</small>
                          <p className="mb-0">Product Name</p>
                          <p>Your Name & User Name</p>
                        </div>
                        <div className="col-3 mx-2 py-md-3 px-2">
                          <small className="font-weight-bold">
                            Routing | ABA
                          </small>
                          <p className="mb-0">1234567</p>
                        </div>
                        <div className="col-3 mx-2 py-md-3 px-2">
                          <small className="font-weight-bold">Address</small>
                          <p className="mb-0">
                            1234 Roader Lane, Costa Mesa, CA 92626 USA
                          </p>
                        </div>
                      </div>
                      <a href="/" className="p-absolute copy ">
                        <img src={copy} alt="copy" className="mr-2" /> copy
                      </a>
                    </div>
                  </div>
                  <div className="alert-text px-3 px-md-5 px-xl-0">
                    <img src={exclamation} alt="Exclamation" />
                    <small className="ml-3 dark-text">
                      <b>
                        To mail a certified check | cashiers check (must be a
                        bank check - no personal checks&nbsp;are&nbsp;accepted):
                      </b>
                    </small>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PaymentOptions;
