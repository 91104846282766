import styled from 'styled-components';

interface IContainer {
  disabled?: boolean;
  active?: boolean;
}

export const Container = styled.div<IContainer>`
  display: block;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  border: ${(props) =>
    props.active ? 'solid 2px var(--greyMineral)' : 'solid 2px transparent'};
  background: ${(props) => (props.active ? 'var(--coal)' : 'transparent')};
  text-align: left;
  border-radius: 15px;
  padding: 1px;
  background-image: none;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image-slice: 1;
  transition-duration: 0.3s;

  :hover {
    text-decoration: none;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
  }

  img {
    width: 24px;
    height: 24px;
  }

  p {
    color: ${(props) => (props.active ? 'none' : 'var(--grayImmensity)')};
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-05 {
    opacity: 0.5;
  }

  .opacity-1 {
    opacity: 1;
  }

  .line-height {
    line-height: 1.7;
  }

  a {
    text-decoration: none;
  }

  > button {
    border: none !important;
    background-color: transparent !important;

    .completed {
      transform: rotateZ(0) !important;
    }
  }
`;
