import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import AutoLogin from '~/pages/AutoLogin';
import SignUp from '~/pages/SignUp';
import ForgotPassword from '~/pages/ForgotPassword';
import ChangePassword from '~/pages/ChangePassword';
import CheckLogin from '~/pages/CheckLogin';
import SignOut from '~/pages/SignOut';
import Home from '~/pages/Home';
import Course from '~/pages/Course';
import Profile from '~/pages/Profile';
import Resources from '~/pages/Resources';
import Affiliate from '~/pages/Affiliate';
import Products from '~/pages/Products';
import Payment from '~/pages/Payment';
import Product from '~/pages/Product';
import Faq from '~/pages/Faq';
import Lesson from '~/pages/Lesson';
import UpdateWarning from '~/pages/UpdateWarning';

const Default: React.FC = () => {
  return (
    <Switch>
      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={UpdateWarning} /> */}
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/auto-login/:password/:email`}
        exact
        component={AutoLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sign-up`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sign-up/i/:referenced`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/forgot-password/:token`}
        exact
        component={ChangePassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/check-login/:token`}
        exact
        component={CheckLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        isPrivate
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/preview/:slug`}
        exact
        isPrivate
        component={Course}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug`}
        exact
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug/:slugLesson`}
        exact
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/profile`}
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resources`}
        isPrivate
        component={Resources}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/become-affiliate`}
        isPrivate
        component={Affiliate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/products`}
        isPrivate
        component={Products}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/product/:slug`}
        exact
        isPrivate
        component={Product}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/product/:slug/payment`}
        exact
        isPrivate
        component={Payment}
      />
      <Route path={`${process.env.PUBLIC_URL}/faq`} isPrivate component={Faq} />
      <Route
        path={`${process.env.PUBLIC_URL}/sign-out`}
        isPrivate
        component={SignOut}
      />
    </Switch>
  );
};

export default Default;
