/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import { useSetup } from '~/hooks/Setup';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer, Background } from './styles';
import Input from '~/components/Input';

import wiserr from '~/assets/defaults/wiserr.png';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const { language } = useLanguage();
  const {
    setupName,
    logo,
    logoMobile,
    loginBackground,
    socialMedias,
    socialUserName,
  } = useSetup();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email(language.sign_in.erro_1)
            .required(language.sign_in.erro_2),
          password: Yup.string().required(language.sign_in.erro_3),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.sign_in.erro_4, 'error');
        }
      }
    },
    [
      language.sign_in.erro_1,
      language.sign_in.erro_2,
      language.sign_in.erro_3,
      language.sign_in.erro_4,
      signIn,
    ]
  );

  return (
    <Container className="d-flex align-items-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center justify-content-lg-start align-items-center h-100">
          <Background
            src={loginBackground}
            className="d-none d-lg-block col-lg-6 text-center"
          >
            <div className="h-100 d-flex flex-column align-items-center justify-content-between py-5 content">
              <img src={logo} alt="Logo" />
              <div className="text-center w-100">
                <div className="d-flex justify-content-center">
                  {socialMedias.map((socialMedia) => (
                    <a
                      key={socialMedia.link}
                      href={socialMedia.link}
                      target="blank"
                    >
                      <img
                        src={socialMedia.icon}
                        alt="Social Media"
                        className="mx-2"
                      />
                    </a>
                  ))}
                </div>
                <p className="d-block my-2">{socialUserName}</p>
              </div>
            </div>
          </Background>
          {setupName === 'wiserr' && (
            <div className="p-absolute position d-none d-md-block">
              <img src={wiserr} alt="Wiserr" />
            </div>
          )}
          <AnimationContainer className="col-11 col-sm-9 col-lg-6 px-0 px-sm-3 my-5 my-lg-0">
            <div className="align-items-center mb-xl-3 shadow">
              <div className="p-xxl-5 p-lg-4 p-sm-5 p-4 login">
                <div className="col-12 text-center text-sm-right d-lg-none mb-4 mb-sm-n4">
                  <img src={logoMobile} alt="logo" className="" />
                </div>
                <h1 className="h2 h1-md text-center text-sm-left font-weight-bold mb-3">
                  {language.sign_in.h1}
                </h1>
                <p className="text-center text-sm-left font-weight-400">
                  {language.sign_in.p_1}
                </p>
                {/* <div className="w-100 row mx-0">
                  <div className="col-lg-6">
                    <button
                      type="button"
                      className="login py-2 px-3 d-flex w-100 mt-3"
                    >
                      <img
                        src={facebookLogo}
                        alt="facebookLogo"
                        className="pr-3"
                      />
                      Continuar com Facebook
                    </button>
                  </div>
                  <div className="col-lg-6 text-right">
                    <button
                      type="button"
                      className="login py-2 px-3 d-flex w-100 mt-3"
                    >
                      <img src={googleLogo} alt="googleLogo" className="pr-3" />
                      Continuar com Google
                    </button>
                  </div>
                </div> */}
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <label htmlFor="email" className="d-block mb-1 mt-3 small">
                    {language.sign_in.label_1}
                  </label>
                  <Input id="email" name="email" className="bg-transparent" />
                  <label htmlFor="password" className="d-block mb-1 mt-4 small">
                    {language.sign_in.label_2}
                  </label>
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    className="bg-transparent btn-show-custom"
                  />
                  <Link
                    to={`${process.env.PUBLIC_URL}/forgot-password`}
                    className="small mb-xl-3 mb-xxl-5 w-100 mt-2 mt-md-4 text-right"
                  >
                    {language.sign_in.a_1}
                  </Link>
                  <button
                    type="submit"
                    className="button-login my-5 my-xxl-0 w-100"
                  >
                    <span className="text-white font-weight-bold d-block px-5 py-3">
                      {language.sign_in.button}
                    </span>
                  </button>
                </Form>
                <div className="text-center">
                  <p className="small normal-sm">
                    {language.sign_in.p_2}{' '}
                    <Link className="font-weight-bold" to="/sign-up">
                      {language.sign_in.a_2}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </AnimationContainer>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
