import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IContainerProps {
  active: boolean;
}

interface IPropsBox {
  src: string;
}

export const Container = styled.div<IContainerProps>`
  padding: 0 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  top: ${(props) => (props.active ? '20px' : '10vh')};
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  z-index: 100;
`;

export const Content = styled.div`
  padding: 20px;
  width: 100%;
  min-height: max-content;
  height: 100%;
  background-color: var(--coal);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const Box = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 250px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    a {
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;

      :hover {
        color: var(--blizzardLighten);
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--ultraWhite);
    }
  }

  @media screen and (min-width: 1800px) {
    height: 298px;
  }
`;
