import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import video from '~/assets/temp/video.png';

interface ILessonData {
  coachLocked?: boolean;
}

export const Container = styled.div``;

export const Skeleton = styled.div`
  .skeleton-player {
    height: 400px !important;
    width: 100% !important;
  }

  .skeleton-arrow {
    width: 38px;
    height: 38px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 575px) {
    .skeleton-player {
      height: 200px !important;
    }
  }
`;

export const Welcome = styled.div`
  position: relative;

  p {
    color: var(--technologicalGray);
  }

  .teste {
    color: #f00;
  }

  @media screen and (max-width: 575px) {
    .arrow-xs {
      width: 30px;
      height: 30px;
    }
  }
`;

export const Locked = styled.div`
  img {
    width: 50px;
    height: 50px;
  }

  .lock-message {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 330px;
    z-index: 1;
    padding: 10px;
    border-radius: 10px;
    right: 20px;
    top: 55px;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
    transition-duration: 0.3s;
  }

  :hover {
    .lock-message {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const LessonData = styled.div<ILessonData>`
  position: relative;

  .lock-message {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 330px;
    z-index: 1;
    padding: 10px;
    border-radius: 10px;
    right: 20px;
    top: -70px;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
    transition-duration: 0.3s;
  }

  :hover {
    .lock-message {
      opacity: ${(props) => (props.coachLocked ? '1' : '0')};
      visibility: ${(props) => (props.coachLocked ? 'visible' : 'hidden')};
    }
  }

  h3 {
    color: var(--graniteGray);
  }

  h4 {
    color: var(--blizzard);
  }

  .player {
    height: 400px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
  }

  hr {
    background-color: var(--closedGray);
  }

  p {
    color: var(--grayImmensity);
  }

  .border-gray {
    border-color: var(--grayImmensity) !important;
  }

  @media screen and (min-width: 1200px) {
    .description {
      height: 4vw;
      max-height: 50px;
      overflow: auto;
    }
  }

  /* @media screen and (min-width: 1700px) {
    .description {
      height: auto;
      overflow: auto;
    }
  } */

  @media screen and (max-width: 575px) {
    .player {
      height: 200px;
    }
  }
`;

export const Video = styled.div`
  width: 100%;
  height: 350px;
  background: var(--midnight);
  border-radius: 20px;
  background-image: url(${video});
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Lessons = styled.div`
  overflow-y: auto;

  @media screen and (min-width: 1200px) {
    height: 10vw !important;
    max-height: 295px;
  }

  @media screen and (min-width: 1300px) {
    height: 12vw !important;
  }

  @media screen and (max-width: 1199px) {
    height: 131px !important;
  }
`;

export const Modules = styled.div``;

export const Calendar = styled.aside`
  width: 380px;

  .DayPicker {
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    border-radius: 10px;
    background-color: var(--coal);
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: var(--technologicalGray) 591 !important;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: -10px;
    padding: 0 1em 1em;
    color: var(--technologicalGray);

    > div {
      text-align: center;
    }
  }

  .DayPicker-Weekday {
    color: var(--blizzard);
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: transparent;
    border-radius: 50%;
    color: var(--technologicalGray);
    border: 1px solid var(--technologicalGray);
    font-weight: bold;
    transition-duration: 0.3s;
  }

  .DayPicker-Day--outside {
    background: transparent;
    border-radius: 50%;
    color: var(--greyMineral);
    border: 1px solid var(--greyMineral);
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: var(--technologicalGrayDarken);
    color: var(--closedGray) !important;
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: var(--deepGrey) !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    border: 1px solid var(--technologicalGray) !important;
    background: var(--technologicalGray) !important;
    color: var(--closedGray) !important;
    font-weight: bold;
  }
`;

export const ModalCoach = styled(ModalComponent)`
  .group {
    max-width: 380px;

    .input-calendar {
      border: 1px solid var(--closedGray);
      border-radius: 15px;
      height: 45px;
    }
  }
`;

export const SideBar = styled.div`
  min-width: 321px;
  max-width: 322px;
  background-color: var(--coal);

  @media (min-width: 992px) and (max-width: 1399px) {
    min-width: 269px;
    max-width: 270px;
  }

  @media screen and (max-width: 1199px) {
    background-color: transparent;
  }

  @media screen and (max-width: 991px) {
    max-width: unset;
  }
`;
