import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import bgMkt from '~/assets/defaults/marketing.png';
import bgEco from '~/assets/defaults/ecommerce.png';
import bgAdv from '~/assets/defaults/advertising.png';
import bgSales from '~/assets/defaults/sales.png';
import arrowNext from '~/assets/icons/arrow-next.svg';
import arrowPrev from '~/assets/icons/arrow-prev.svg';

interface IAvatarProps {
  src: string;
}

interface IContainerProps {
  background: string;
}

interface IPropsBox {
  src: string;
}

export const Welcome = styled.div`
  padding: 20px 20px 0;

  h1,
  input {
    color: var(--blizzard);
  }

  h1 {
    span {
      transition-duration: 0.3s;
      visibility: hidden;
      opacity: 0;
    }

    span.active {
      visibility: visible;
      opacity: 1;
    }
  }

  p {
    color: var(--technologicalGray);
  }

  .search {
    input {
      background-color: transparent;
      border: 1px solid #969696;
      border-radius: 10px;
      line-height: 33px;
      height: 38px;
    }
    button {
      right: 15px;
      top: 5px;
      background: transparent;
      border: none;
    }
  }

  @media screen and (min-width: 1740px) {
    .pad-1740 {
      padding: 0 0.2rem !important;
    }
  }
`;

export const Container = styled.div<IContainerProps>`
  background: url(${(props) => props.background});

  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 70%;
  position: relative;

  h1 {
    color: #f5f5f5;
    font-size: 80px;
    font-weight: 600;
  }

  p {
    color: var(--graniteGray);
  }

  ::before {
    content: '';
    position: absolute;
    background: linear-gradient(
        0deg,
        var(--midnight) 4.26%,
        var(--midnightOpacity) 100%
      ),
      linear-gradient(90deg, var(--midnight) 4.26%, var(--midnightOpacity) 100%);
    //backdrop-filter: blur(20px);
    width: 100%;
    height: 100%;
  }

  .container,
  .container-lg {
    max-width: 1600px !important;
  }

  .old-price {
    color: var(--error);
  }

  .button-play {
    background-color: var(--midnight);
    border: none;
    border-radius: 20px;

    :hover {
      background-color: 'var(--midnightDarken)';
      transition: 0.5s;
    }
  }

  .btn-play {
    font-family: 'Poppins';
    width: 267px;
    border-radius: 22px;
    background: linear-gradient(270.03deg, #fb5a43 0%, #c341ae 100%);
    border: 2px solid rgba(255, 255, 255, 0.8);
    transition-duration: 0.3s;
    font-weight: 600;
    font-size: 20px;
    padding: 11px;

    :hover {
      background: linear-gradient(
        270.03deg,
        ${darken(0.05, '#fb5a43')} 0%,
        ${darken(0.05, '#c341ae')} 100%
      );
    }
  }

  .border-top-courses {
    border-top: 2px solid var(--deepGrey) !important;
  }

  .pd-button {
    padding: 20px 0 !important;
  }

  .category {
    background: linear-gradient(90deg, #fb5a43 0%, #c341ae 100%);
    padding: 2px;

    span {
      background: #18191a;
    }

    + b {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 575px) {
    .arrow-xs {
      width: 30px;
      height: 30px;
    }
  }
`;

export const Courses = styled.div`
  .slick-list {
    padding: 20px 0 !important;
    height: 316px;
  }

  .slick-prev {
    top: 44%;
  }

  .slick-next {
    top: 44%;
  }

  .padding-box {
    padding: 0 20px;
  }
`;

export const Box = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 20vw;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition-duration: 0.3s;
  :hover {
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.45),
      0px 15px 64px -45px rgba(199, 197, 197, 0.42);
    transform: scale(1.11);
  }

  .see-more-link {
    button {
      border: none;
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;

      :hover {
        color: var(--blizzardLighten);
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--blizzard) !important;
    }
  }

  @media screen and (min-width: 1800px) {
    height: 238px;

    .align-center-1800 {
      align-items: center;
    }

    .h3-1800 {
      font-size: 1.75rem !important;
    }
  }

  @media screen and (max-width: 991px) {
    height: 29vw;
  }

  @media screen and (max-width: 575px) {
    height: 50vw;
  }
`;

export const Skeleton = styled.div`
  .container,
  .container-lg {
    max-width: 1600px !important;
  }
  .border-top-courses {
    border-top: 2px solid var(--deepGrey) !important;
  }
  .skeleton-height {
    height: 298px;
  }

  .skeleton-arrow {
    width: 34px;
    height: 34px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 45px;
  height: 45px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 2px var(--blizzardOpacity);
  box-sizing: border-box;
`;

export const BtnFilter = styled.div`
  button {
    color: #f5f5f5;
    font-size: 24px;
    font-weight: 700;
    height: 146px;
    border: none;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 20px;
  }

  .slick-next {
    background: url(${arrowNext});
    background-size: contain;
    background-repeat: no-repeat !important;
    width: 54px !important;
    height: 54px !important;
    top: -50%;
    right: 3%;
    :hover {
      background-repeat: no-repeat !important;
    }
  }

  .slick-next svg {
    display: none;
  }

  .slick-prev {
    background: url(${arrowPrev});
    background-size: contain;
    background-repeat: no-repeat !important;
    width: 54px !important;
    height: 54px !important;
    top: -50%;
    right: 8%;
    left: auto;
    :hover {
      background-repeat: no-repeat !important;
    }
  }

  .slick-prev svg {
    display: none;
  }

  .pad-20 {
    padding: 0 20px;
  }

  .bg-mkt {
    background-image: linear-gradient(
        180deg,
        rgba(24, 25, 26, 0) 0.08%,
        #18191a 92.47%
      ),
      url(${bgMkt});
  }

  .bg-ecom {
    background-image: linear-gradient(
        180deg,
        rgba(24, 25, 26, 0) 0.08%,
        #18191a 92.47%
      ),
      url(${bgEco});
  }

  .bg-adver {
    background-image: linear-gradient(
        180deg,
        rgba(24, 25, 26, 0) 0.08%,
        #18191a 92.47%
      ),
      url(${bgAdv});
  }

  .bg-sales {
    background-image: linear-gradient(
        180deg,
        rgba(24, 25, 26, 0) 0.08%,
        #18191a 92.47%
      ),
      url(${bgSales});
  }
`;
