import React, { useState, useCallback, useEffect } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import {
  AiOutlineQuestionCircle,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
} from 'react-icons/ai';
import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { Introduction, Container } from './styles';
import ButtonLesson from '~/components/ButtonLesson';
import Quizzes, { IQuestion, IAnswers } from '~/components/Quizzes';

import play from '~/assets/icons/play-icon.svg';
import padlock from '~/assets/icons/padlock-icon.svg';

interface IParams {
  slug: string;
  slugLesson: string;
}

interface ILesson {
  id: string;
  title: string;
  duration: string;
  status: string;
  slug: string;
  finished: boolean;
}

interface IModule {
  id: string;
  title: string;
  locked: boolean;
  lessons: ILesson[];
  slug: string;
  type?: 'introduction' | 'exam';
  exam?: {
    id: string;
    questions: IQuestion[];
    answered: boolean;
    allQuestionsCorrect: boolean;
  };
}

interface IModulesProps {
  data: IModule[];
  slugCourse: string;
  onAnsweredExam?(): void;
  onClickFinished?(lesson_id: string): void;
}

const Modules: React.FC<IModulesProps> = ({
  data,
  slugCourse,
  onAnsweredExam,
  onClickFinished,
}) => {
  const params = useParams<IParams>();
  const [moduleSelected, setModuleSelected] = useState('');
  const [show, setShow] = useState(false);
  const [modules, setModules] = useState<IModule[]>([]);

  useEffect(() => {
    setModules(data);
  }, [data]);

  const handleClick = useCallback(
    (value) => {
      if (moduleSelected === value) {
        setModuleSelected('');
      } else {
        setModuleSelected(value);
      }
    },
    [moduleSelected]
  );

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleAnsweredQuestions = useCallback(
    async (answers, exam, module_id) => {
      try {
        const totalQuestions = exam.questions.length;

        const totalCorrectAnswers = answers.reduce(
          (acumulador: number, answer: IAnswers) => {
            const value = answer.correct_answer ? 1 : 0;
            return acumulador + value;
          },
          0
        );

        const notePerQuestion = 10 / totalQuestions;
        const note = notePerQuestion * totalCorrectAnswers;

        const formData = {
          exam_id: exam.id,
          note,
        };

        const response = await api.post('exams-students', formData);
        if (response.data) {
          const newModules = modules.slice();
          const selectedModule = modules.find(
            (module) => module.id === module_id
          );
          const moduleIndex = modules.findIndex(
            (module) => module.id === module_id
          );

          if (selectedModule) {
            if (selectedModule.exam) {
              selectedModule.exam.answered = true;
              if (note === 10) {
                selectedModule.exam.allQuestionsCorrect = true;
              }
              newModules[moduleIndex] = selectedModule;
              setModules(newModules);
            }
          }

          if (onAnsweredExam) {
            onAnsweredExam();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [modules, onAnsweredExam]
  );

  return (
    <>
      {modules.map((module) => (
        <>
          {module.type && module.type === 'introduction' ? (
            <Introduction
              to={`${process.env.PUBLIC_URL}/courses/${slugCourse}/${module.slug}`}
              className="lesson-border w-100 my-1"
              active={params.slugLesson === module.slug}
            >
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex aling-items-center">
                  <img
                    src={play}
                    alt="Icon Button"
                    className={`mr-3 ${
                      params.slugLesson !== module.slug ? 'opacity-0' : ''
                    }`}
                  />
                  <p className="mb-0 small ml-1 line-height">{module.title}</p>
                </div>
              </div>
            </Introduction>
          ) : (
            <Container
              key={module.id}
              active={moduleSelected === module.id}
              // disabled={module.locked}
              qtdLessons={
                module.exam ? module.lessons.length + 1 : module.lessons.length
              }
              className="mb-2"
            >
              <button
                type="button"
                className="w-100"
                onClick={() => handleClick(module.id)}
              >
                <div className="d-flex aling-items-center p-2">
                  {/* {module.locked ? (
                    <img src={padlock} alt="Icon Button" className="mr-3" />
                  ) : (
                    <BiChevronRight
                      size={24}
                      color="#e4e4e4"
                      className="mr-3"
                    />
                    )} */}
                  <BiChevronRight size={24} color="#e4e4e4" className="mr-3" />

                  <p className="mb-0 small ml-1 line-height">{module.title}</p>
                </div>
              </button>
              <div className="pl-3 content">
                {module.lessons.map((lesson) => (
                  <ButtonLesson
                    key={lesson.id}
                    lesson={lesson}
                    slugCourse={slugCourse}
                    onClickFinished={onClickFinished}
                  />
                ))}
                {module.exam && (
                  <>
                    <button
                      type="button"
                      className="btn-exam w-100 my-1"
                      onClick={handleShow}
                    >
                      <div className="d-flex aling-items-center p-2">
                        {module.exam.answered ? (
                          <>
                            {module.exam.allQuestionsCorrect ? (
                              <AiOutlineCheckCircle
                                size={24}
                                color="#e4e4e4"
                                className="mr-3"
                              />
                            ) : (
                              <AiOutlineCloseCircle
                                size={24}
                                color="#e4e4e4"
                                className="mr-3"
                              />
                            )}
                          </>
                        ) : (
                          <AiOutlineQuestionCircle
                            size={24}
                            color="#e4e4e4"
                            className="mr-3"
                          />
                        )}

                        <p className="mb-0 small ml-1 line-height">Exam</p>
                      </div>
                    </button>
                    <Quizzes
                      dataQuestions={module.exam.questions}
                      onAnsweredQuestions={(answers) =>
                        handleAnsweredQuestions(answers, module.exam, module.id)
                      }
                      answered={false}
                      hideButton
                      onCloseModal={handleClose}
                      showQuestions={show && moduleSelected === module.id}
                    />
                  </>
                )}
              </div>
            </Container>
          )}
        </>
      ))}
    </>
  );
};

export default Modules;
