/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import getValidationErros from '~/utils/getValidationsErrors';
import { useSetup } from '~/hooks/Setup';
import api from '~/services/api';

import { Container, AnimationContainer, Background } from './styles';
import Input from '~/components/Input';

import wiserr from '~/assets/defaults/wiserr.png';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  referenced: string;
}

interface IParamsProps {
  referenced: string;
}

const SignUp: FC = () => {
  const history = useHistory();
  const params = useParams<IParamsProps>();
  const formRef = useRef<FormHandles>(null);
  const { signUp } = useAuth();
  const { language } = useLanguage();
  const {
    setupName,
    logo,
    logoMobile,
    loginBackground,
    socialMedias,
    socialUserName,
  } = useSetup();
  const [loading, setLoading] = useState(false);
  const [affiliateId, setAffiliateId] = useState('');
  const [affiliate, setAffiliate] = useState('');

  useEffect(() => {
    if (params.referenced) {
      setLoading(true);
      api
        .get(`students/@${params.referenced}`)
        .then((response) => {
          setAffiliateId(response.data.affiliate.id);
          setAffiliate(`@${params.referenced}`);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'Student not found'
          ) {
            Swal.fire('Opss...', language.sign_up.erro_7, 'error').then(() => {
              history.push(`${process.env.PUBLIC_URL}/sign-up`);
              setLoading(false);
            });
          } else {
            Swal.fire('Opss...', language.sign_up.erro_8, 'error').then(() =>
              setLoading(false)
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [history, language.sign_up.erro_7, language.sign_up.erro_8, params]);

  const handleChange = useCallback((e) => {
    let { value } = e.target;

    if (value[0] !== '@') {
      value = `@${value}`;
    }

    setAffiliate(value);
  }, []);

  const handleBlur = useCallback(
    (e) => {
      const value = e.target.value.substring(1);
      if (value.length > 0) {
        setLoading(true);
        api
          .get(`students/@${value}`)
          .then((response) => {
            setAffiliateId(response.data.affiliate.id);
            setAffiliate(`@${value}`);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message === 'Student not found'
            ) {
              Swal.fire('Opss...', language.sign_up.erro_7, 'error');
            } else {
              Swal.fire('Opss...', language.sign_up.erro_8, 'error');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setAffiliate('');
      }
    },
    [language.sign_up.erro_7, language.sign_up.erro_8]
  );

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(language.sign_up.erro_1),
          email: Yup.string()
            .email(language.sign_up.erro_2)
            .required(language.sign_up.erro_3),
          password: Yup.string().required(language.sign_up.erro_4),
          referenced: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signUp({
          name: data.name,
          email: data.email,
          password: data.password,
          referenced: data.referenced
            ? affiliateId
            : '8cd43790-eb93-4754-9661-dba1efcc49ec',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.sign_up.erro_6, 'error');
        }
      }
    },
    [
      affiliateId,
      language.sign_up.erro_1,
      language.sign_up.erro_2,
      language.sign_up.erro_3,
      language.sign_up.erro_4,
      language.sign_up.erro_6,
      signUp,
    ]
  );

  return (
    <>
      <Helmet>
        <title>Wiserr - Sign up</title>
      </Helmet>
      <Container className="d-flex align-items-center">
        <div className="container-fluid h-100">
          <div className="row justify-content-center justify-content-lg-start align-items-center h-100">
            <Background
              src={loginBackground}
              className="d-none d-lg-block col-lg-6 text-center"
            >
              <div className="h-100 d-flex flex-column align-items-center justify-content-between py-5 content">
                <img src={logo} alt="Logo" />
                <div className="text-center w-100">
                  <div className="d-flex justify-content-center">
                    {socialMedias.map((socialMedia) => (
                      <a
                        key={socialMedia.link}
                        href={socialMedia.link}
                        target="blank"
                      >
                        <img
                          src={socialMedia.icon}
                          alt="Social Media"
                          className="mx-2"
                        />
                      </a>
                    ))}
                  </div>
                  <p className="d-block my-2">{socialUserName}</p>
                </div>
              </div>
            </Background>
            {setupName === 'wiserr' && (
              <div className="p-absolute position d-none d-md-block">
                <img src={wiserr} alt="Wiserr" />
              </div>
            )}
            <AnimationContainer className="col-11 col-sm-9 col-lg-6 px-0 px-sm-3 my-5 my-lg-0">
              <div className="align-items-center mb-xl-3 shadow">
                <div className="p-xxl-5 p-lg-4 p-md-5 p-4 login">
                  <div className="col-12 text-center d-lg-none mb-4">
                    <img src={logoMobile} alt="logo" className="" />
                  </div>
                  <h1 className="h5 h2-sm h3-lg h1-xxl text-center text-md-left font-weight-bold mb-3">
                    {language.sign_up.h1}
                  </h1>
                  <p className="text-center text-md-left">
                    {language.sign_up.p_1}
                  </p>
                  {/* <div className="w-100 row mx-0">
                  <div className="col-lg-6">
                    <button
                      type="button"
                      className="login py-2 px-3 d-flex w-100 mt-3"
                    >
                      <img
                        src={facebookLogo}
                        alt="facebookLogo"
                        className="pr-3"
                      />
                      Continuar com Facebook
                    </button>
                  </div>
                  <div className="col-lg-6 text-right">
                    <button
                      type="button"
                      className="login py-2 px-3 d-flex w-100 mt-3"
                    >
                      <img src={googleLogo} alt="googleLogo" className="pr-3" />
                      Continuar com Google
                    </button>
                  </div>
                </div> */}
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <label htmlFor="name" className="d-block mb-1 mt-3 small">
                      {language.sign_up.label_1}
                    </label>
                    <Input id="name" name="name" className="bg-transparent" />
                    <label htmlFor="email" className="d-block mb-1 mt-3 small">
                      {language.sign_up.label_2}
                    </label>
                    <Input id="email" name="email" className="bg-transparent" />
                    <label
                      htmlFor="password"
                      className="d-block mb-1 mt-3 small"
                    >
                      {language.sign_up.label_3}
                    </label>
                    <Input
                      id="password"
                      type="password"
                      name="password"
                      className="bg-transparent btn-show-custom"
                    />
                    <label
                      htmlFor="referenced"
                      className="d-block mb-1 mt-3 small"
                    >
                      {language.sign_up.label_4}
                    </label>
                    <Input
                      id="referenced"
                      name="referenced"
                      className="bg-transparent"
                      value={affiliate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={!!params.referenced}
                    />
                    <div className="ml-3 border-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termos"
                      />
                      <label className="small d-400-flex" htmlFor="termos">
                        {language.sign_up.p_2}&nbsp;
                        <Link to="/">
                          <u>{language.sign_up.a_1}</u>
                        </Link>
                      </label>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap border-0">
                      <button type="submit" className="button-login mt-2 w-100">
                        <span className="text-white font-weight-bold d-block px-5 py-3">
                          {language.sign_up.button_2}
                        </span>
                      </button>
                    </div>
                  </Form>
                  <div className="text-center mt-3">
                    <p className="small normal-sm">
                      {language.sign_up.p_3}{' '}
                      <Link className="font-weight-bold" to="/">
                        {language.sign_up.a_2}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </AnimationContainer>
          </div>
        </div>
      </Container>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
