import styled from 'styled-components';

export const Container = styled.div`
  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;
    height: 102px;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
export const Action = styled.div`
  background: var(--coal);
  border-radius: 20px;
`;

export const Options = styled.div`
  border-radius: 15px;
  padding: 1px;
  background-image: linear-gradient(
      0deg,
      var(--asphaltGray),
      var(--asphaltGray)
    ),
    linear-gradient(180deg, var(--greyMineral) 0%, var(--greyMineral) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image-slice: 1;
  box-shadow: 0px 4px 8px transparent;

  button:not(.options-button) {
    color: var(--blizzardOpacity);
    border-bottom: 1px solid transparent !important;
    padding: 5px 0;

    :hover {
      color: var(--blizzard);
      border-color: var(--blizzard) !important;
    }
  }

  button.active {
    color: var(--blizzard);
    border-color: var(--blizzard) !important;
  }

  @media screen and (max-width: 991px) {
    .options-button {
      transition-duration: 0.3s;
      background: var(--midnight);
      border-radius: 20px;

      p {
        color: var(--ultraWhite);
        font-weight: 700 !important;
      }

      svg {
        transition-duration: 0.3s;
        color: var(--ultraWhite);
      }
    }

    .options {
      transition-duration: 0.3s;
      background: var(--coal);
      color: var(--technologicalGray);
      border-radius: 0 0 20px 20px;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

      .bg-btn {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        width: 100%;

        + .bg-btn {
          border-top: solid 1px var(--blizzardOpacity) !important;
        }
      }
    }

    .options-box.active {
      .options-button {
        border-radius: 20px 20px 0px 0px;

        svg {
          transform: rotate(180deg);
        }
      }

      .options {
        height: 200px !important;
        padding: 0;
        overflow: none;

        ::-webkit-scrollbar {
          background-color: var(--coal);
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: var(--asphaltGray);
        }
      }
    }

    button:hover,
    button.active {
      border-bottom: none !important;
    }
  }

  @media screen and (max-width: 767px) {
    .w-33-mobile {
      width: 33.333%;
    }
  }
`;

export const Content = styled.div`
  p {
    color: var(--greyMineral);
  }

  h2 {
    color: var(--graniteGray);
    font-size: 1.5rem;
  }

  input {
    background-color: transparent;
    border: 1px solid var(--deepGrey);
    border-radius: 35px;
  }

  .img-height {
    height: 150px;
  }

  .send {
    right: 38px;
    top: 12px;
  }

  .color-light {
    color: var(--graniteGray);
  }

  .comment {
    background-color: var(--closedGray);
    border-radius: 20px;
    min-width: 170px;

    p:nth-child(1) {
      color: var(--ultraWhite);
    }

    p:nth-child(2) {
      color: var(--technologicalGray);
    }
  }

  .comment-actions {
    min-width: 170px;
  }

  .comment-button {
    background-color: transparent;
    border: none;
    color: var(--deepGrey);
  }

  .next-step {
    .coach {
      padding: 2px;
      background-color: var(--asphaltGray);
      border-radius: 20px;

      .coach-avatar {
        padding: 3px;
        background-image: linear-gradient(0deg, var(--coal), var(--coal)),
          radial-gradient(
            circle at top left,
            var(--blizzardOpacity),
            var(--blizzardOpacity)
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
        border-image-slice: 1;
        border-radius: 60px;
      }
    }
  }

  .discuss-img {
    width: 70px;
    height: 70px;
  }

  .spacer {
    width: 70px;
  }

  .special-note {
    padding: 3px;
  }

  .downloads {
    img {
      width: 100%;
      border-radius: 10px;
    }

    .bg-shadow {
      border-radius: 10px;
      background: var(--midnight) 5e;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 10px;
      width: calc(100% - 30px);

      p {
        color: var(--ultraWhite);
      }
    }
  }

  .box {
    border-radius: 15px;
    padding: 1px;
    background-image: linear-gradient(
        0deg,
        var(--asphaltGray),
        var(--asphaltGray)
      ),
      linear-gradient(180deg, var(--greyMineral) 0%, var(--greyMineral) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    box-shadow: 0px 4px 8px transparent;

    img {
      border-radius: 15px;
    }

    p {
      color: var(--blizzard);
    }

    :hover {
      text-decoration: none !important;
      p {
        color: var(--blizzardLighten);
      }
    }
  }

  @media screen and (max-width: 991px) {
    .discuss-img {
      width: 50px;
      height: 50px;
    }

    .spacer {
      width: 50px;
    }
  }

  @media screen and (max-width: 575px) {
    .img-height {
      height: 220px;
    }
  }

  @media screen and (max-width: 490px) {
    .img-height {
      height: 200px;
    }
  }
`;
