import React, { useEffect, useState, useRef, useCallback } from 'react';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, MyCertificates, Box, BoxLoading } from './styles';
import PdfCertificate from '~/components/PdfCertificate';
import certificateImage from '~/assets/defaults/certificate-new.png';

import award from '~/assets/icons/award.svg';
import download from '~/assets/icons/download.svg';
import noCertificates from '~/assets/icons/certificates-icon.svg';

interface ICertificate {
  thumb?: string;
  title: string;
  certificate_url: string;
}
interface ICertificatesProps {
  active: boolean;
}

const Certificates: React.FC<ICertificatesProps> = ({ active }) => {
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [certificates, setCertificates] = useState<ICertificate[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .get('certificates')
      .then((response) => {
        setCertificates(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleChangeThumb = useCallback(
    (thumb, index) => {
      const newCertificates = certificates.slice();
      newCertificates[index].thumb = thumb;
      setCertificates(newCertificates);
    },
    [certificates]
  );

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <MyCertificates>
        <div className="container-fluid">
          <div className="row pt-3">
            <div className="col-12 mb-4">
              <h3 className="h5">{language.certificates.h3}</h3>
            </div>
            {loading && (
              <>
                <div className="col-sm-6 col-xl-4 mb-5">
                  <BoxLoading />
                </div>
                <div className="col-sm-6 col-xl-4 mb-5">
                  <BoxLoading />
                </div>
                <div className="col-sm-6 col-xl-4 mb-5">
                  <BoxLoading />
                </div>

                <div className="col-sm-6 col-xl-4 mb-5">
                  <BoxLoading />
                </div>
                <div className="col-sm-6 col-xl-4 mb-5">
                  <BoxLoading />
                </div>
                <div className="col-sm-6 col-xl-4 mb-5">
                  <BoxLoading />
                </div>
              </>
            )}
            {certificates.length > 0 ? (
              <>
                {certificates.map((certificate, index) => (
                  <div
                    key={certificate.certificate_url}
                    className="col-sm-6 col-xl-4"
                  >
                    <PdfCertificate
                      filePdf={certificate.certificate_url}
                      onChangeThumb={(thumb) => handleChangeThumb(thumb, index)}
                    />
                    <Box
                      src={certificate.thumb || certificateImage}
                      className="course-link d-block p-relative thumb w-100"
                    >
                      <div className="short-title w-100 p-2 p-absolute">
                        <div className="row h-100">
                          <div className="col-12">
                            <img
                              src={award}
                              alt="award"
                              className="award float-right mr-3 mt-n2"
                            />
                          </div>
                        </div>
                      </div>
                    </Box>

                    <div className="mt-2 mb-3 text-center">
                      <a
                        href={certificate.certificate_url}
                        target="blank"
                        className="d-block w-100 border-0 p-relative"
                      >
                        {certificate.title}
                        <img src={download} alt="download" className="ml-2" />
                      </a>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={`${loading === true ? 'd-none' : ''} col-12`}>
                <div className="row h-40vh align-items-center justify-content-center">
                  <div className="col-md-8 d-md-flex text-center align-items-md-center justify-content-md-center">
                    <img
                      src={noCertificates}
                      className="mb-3 mb-md-0 mx-2"
                      alt="No Certificates icon "
                    />
                    <p className="h5 mb-0 pl-md-3 text-no-certificates text-center">
                      {language.no_register.p}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </MyCertificates>
    </Container>
  );
};

export default Certificates;
