import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IContainer {
  disabled?: boolean;
  active?: boolean;
  qtdLessons?: number;
}

export const Introduction = styled(Link)<IContainer>`
  display: block;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  border: ${(props) =>
    props.active ? 'solid 2px var(--greyMineral)' : 'solid 2px transparent'};
  background: ${(props) => (props.active ? 'var(--coal)' : 'transparent')};
  text-align: left;
  border-radius: 15px;
  padding: 1px;
  background-image: none;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image-slice: 1;
  transition-duration: 0.3s;

  :hover {
    text-decoration: none;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
  }

  img {
    width: 24px;
    height: 24px;
  }

  p {
    color: ${(props) => (props.active ? 'none' : 'var(--grayImmensity)')};
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-05 {
    opacity: 0.5;
  }

  .opacity-1 {
    opacity: 1;
  }

  .line-height {
    line-height: 1.7;
  }
`;

export const Container = styled.div<IContainer>`
  button:not(.btn-exam) {
    display: block;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
    border: ${(props) =>
      props.active ? 'solid 2px var(--greyMineral)' : 'solid 2px transparent'};
    background: ${(props) => (props.active ? 'var(--coal)' : 'transparent')};
    text-align: left;
    border-radius: 15px;
    padding: 1px;
    background-image: none;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      border: solid 2px var(--greyMineral);
      background: var(--coal);
    }

    svg {
      transition-duration: 0.3s;
      transform: ${(props) => (props.active ? 'rotateZ(90deg)' : 'rotateZ(0)')};
    }

    img {
      width: 24px;
      height: 24px;
    }

    p {
      color: ${(props) => (props.active ? 'none' : 'var(--grayImmensity)')};
    }

    .opacity-0 {
      opacity: 0;
    }

    .opacity-05 {
      opacity: 0.5;
    }

    .opacity-1 {
      opacity: 1;
    }

    .line-height {
      line-height: 1.7;
    }
  }

  .btn-exam {
    display: block;
    border: solid 2px transparent;
    background: transparent;
    text-align: left;
    border-radius: 15px;
    padding: 1px;
    background-image: none;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    transition-duration: 0.3s;

    :hover {
      text-decoration: none;
      border: solid 2px var(--greyMineral);
      background: var(--coal);
    }

    img {
      width: 24px;
      height: 24px;
    }

    p {
      color: ${(props) => (props.active ? 'none' : 'var(--grayImmensity)')};
    }

    .opacity-0 {
      opacity: 0;
    }

    .opacity-05 {
      opacity: 0.5;
    }

    .opacity-1 {
      opacity: 1;
    }
  }

  .content {
    overflow: hidden;
    transition-duration: 0.3s;
    height: ${(props) =>
      props.active && props.qtdLessons ? `${props.qtdLessons * 75}px` : '0'};
  }
`;
